import { SongType } from "../player/utils";

export const SamplerSuperSawtooth: SongType = {
  id: 'ymef7DmAqE',
  beat: 4,
  tempo: 145,
  tonic: 'a',
  scale: 'chromatic',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: 'fux84l',
        items: [
          {
            S: 0,
            L: 18,
            y: 24,
            id: 'eUKeEr',
          },
          {
            S: 18,
            L: 18,
            y: 24,
            id: 'HVb4LD',
          },
          {
            S: 36,
            L: 18,
            y: 24,
            id: '-Q1_3G',
          },
          {
            S: 54,
            L: 18,
            y: 24,
            id: 'MjmUMI',
          },
          {
            S: 72,
            L: 36,
            y: 24,
            id: '-ivI6I',
          },
          {
            S: 108,
            L: 18,
            y: 24,
            id: 'TLHgi-',
          },
          {
            S: 126,
            L: 18,
            y: 24,
            id: 'SkCVLX',
          },
          {
            S: 144,
            L: 18,
            y: 24,
            id: 'T2WA5-',
          },
          {
            S: 162,
            L: 18,
            y: 24,
            id: '6IH7H0',
          },
          {
            S: 180,
            L: 18,
            y: 24,
            id: '9qJRxu',
          },
          {
            S: 198,
            L: 18,
            y: 24,
            id: 'kVsdIi',
          },
          {
            S: 252,
            L: 18,
            y: 19,
            id: '4HYWKz',
          },
          {
            S: 270,
            L: 18,
            y: 19,
            id: 'NF-ZBN',
          },
          {
            S: 0,
            L: 18,
            y: 17,
            id: '1Hjazv',
          },
          {
            S: 18,
            L: 18,
            y: 17,
            id: 'QBIcZS',
          },
          {
            S: 36,
            L: 18,
            y: 17,
            id: 'UI_S6p',
          },
          {
            S: 54,
            L: 18,
            y: 17,
            id: 'rLLA9e',
          },
          {
            S: 72,
            L: 36,
            y: 17,
            id: 'WXtHy7',
          },
          {
            S: 108,
            L: 18,
            y: 17,
            id: 'DcjfCl',
          },
          {
            S: 126,
            L: 18,
            y: 17,
            id: 'P-B1Wv',
          },
          {
            S: 144,
            L: 18,
            y: 17,
            id: 'Kupu4p',
          },
          {
            S: 162,
            L: 18,
            y: 17,
            id: 'U0Lf_1',
          },
          {
            S: 180,
            L: 18,
            y: 17,
            id: 'OD5Xyj',
          },
          {
            S: 198,
            L: 18,
            y: 17,
            id: 'Nc6AQT',
          },
          {
            S: 216,
            L: 36,
            y: 17,
            id: 'FoMVr_',
          },
          {
            S: 252,
            L: 18,
            y: 12,
            id: 'cYiZ9x',
          },
          {
            S: 270,
            L: 18,
            y: 12,
            id: 'u8HxzJ',
          },
          {
            S: 288,
            L: 18,
            y: 19,
            id: 'YV4Hp9',
          },
          {
            S: 306,
            L: 18,
            y: 19,
            id: '54yZ8s',
          },
          {
            S: 324,
            L: 18,
            y: 19,
            id: 'xLsCOk',
          },
          {
            S: 342,
            L: 18,
            y: 19,
            id: '_lP9_e',
          },
          {
            S: 360,
            L: 36,
            y: 19,
            id: 'Gcfo_l',
          },
          {
            S: 396,
            L: 18,
            y: 21,
            id: 'yNWy_X',
          },
          {
            S: 414,
            L: 18,
            y: 21,
            id: 'rZWZVj',
          },
          {
            S: 432,
            L: 18,
            y: 21,
            id: 'dbeYZp',
          },
          {
            S: 450,
            L: 18,
            y: 21,
            id: 'vs11dx',
          },
          {
            S: 468,
            L: 18,
            y: 21,
            id: 'urTTJi',
          },
          {
            S: 486,
            L: 18,
            y: 21,
            id: 'rn2zCQ',
          },
          {
            S: 504,
            L: 36,
            y: 21,
            id: 'hAlzpS',
          },
          {
            S: 540,
            L: 18,
            y: 26,
            id: '7xI54T',
          },
          {
            S: 558,
            L: 18,
            y: 26,
            id: '7RHRr7',
          },
          {
            S: 288,
            L: 18,
            y: 12,
            id: 'G-pcm9',
          },
          {
            S: 306,
            L: 18,
            y: 12,
            id: 'G_Bqh1',
          },
          {
            S: 324,
            L: 18,
            y: 12,
            id: '3dWjsq',
          },
          {
            S: 342,
            L: 18,
            y: 12,
            id: '21yFNu',
          },
          {
            S: 360,
            L: 36,
            y: 12,
            id: 'phTdwR',
          },
          {
            S: 396,
            L: 18,
            y: 14,
            id: 'Ba6ssl',
          },
          {
            S: 414,
            L: 18,
            y: 14,
            id: 'JIfuEc',
          },
          {
            S: 432,
            L: 18,
            y: 14,
            id: '7hrTLo',
          },
          {
            S: 450,
            L: 18,
            y: 14,
            id: '7rGpYN',
          },
          {
            S: 468,
            L: 18,
            y: 14,
            id: 'bkFeWt',
          },
          {
            S: 486,
            L: 18,
            y: 14,
            id: 'z8oFHh',
          },
          {
            S: 504,
            L: 36,
            y: 14,
            id: 'p-YRvP',
          },
          {
            S: 540,
            L: 18,
            y: 19,
            id: '-9h9NN',
          },
          {
            S: 558,
            L: 18,
            y: 19,
            id: 'XyL3XG',
          },
          {
            S: 0,
            L: 18,
            y: 21,
            id: '687LLv',
          },
          {
            S: 18,
            L: 18,
            y: 21,
            id: '4Xm2pC',
          },
          {
            S: 36,
            L: 18,
            y: 21,
            id: 'p4M3xT',
          },
          {
            S: 54,
            L: 18,
            y: 21,
            id: 'Mb66GB',
          },
          {
            S: 72,
            L: 36,
            y: 21,
            id: 'Kmch9G',
          },
          {
            S: 108,
            L: 18,
            y: 21,
            id: '_95Pr-',
          },
          {
            S: 126,
            L: 18,
            y: 21,
            id: 'xcGKiD',
          },
          {
            S: 144,
            L: 18,
            y: 21,
            id: 'MpGcJc',
          },
          {
            S: 162,
            L: 18,
            y: 21,
            id: 'mhogSO',
          },
          {
            S: 180,
            L: 18,
            y: 21,
            id: 'ujL0A6',
          },
          {
            S: 198,
            L: 18,
            y: 21,
            id: 'MYWldo',
          },
          {
            S: 216,
            L: 36,
            y: 21,
            id: 'ILtrlf',
          },
          {
            S: 216,
            L: 36,
            y: 24,
            id: 'LfpiDP',
          },
          {
            S: 252,
            L: 18,
            y: 16,
            id: 'iWVMIw',
          },
          {
            S: 270,
            L: 18,
            y: 16,
            id: 'HWzYvB',
          },
          {
            S: 288,
            L: 18,
            y: 16,
            id: '8ILqRr',
          },
          {
            S: 306,
            L: 18,
            y: 16,
            id: '1hK9Sy',
          },
          {
            S: 324,
            L: 18,
            y: 16,
            id: 'sp5axO',
          },
          {
            S: 342,
            L: 18,
            y: 16,
            id: '-jfU3Z',
          },
          {
            S: 360,
            L: 36,
            y: 16,
            id: 'zFwwyM',
          },
          {
            S: 396,
            L: 18,
            y: 17,
            id: 'G-Up_B',
          },
          {
            S: 414,
            L: 18,
            y: 17,
            id: 'bQfmAp',
          },
          {
            S: 432,
            L: 18,
            y: 17,
            id: 'uEa72c',
          },
          {
            S: 450,
            L: 18,
            y: 17,
            id: '5xEa93',
          },
          {
            S: 468,
            L: 18,
            y: 17,
            id: 'SimwnB',
          },
          {
            S: 486,
            L: 18,
            y: 17,
            id: 'eZoxCx',
          },
          {
            S: 504,
            L: 36,
            y: 17,
            id: 'SYjIoK',
          },
          {
            S: 540,
            L: 18,
            y: 22,
            id: 'kkp-L5',
          },
          {
            S: 558,
            L: 18,
            y: 22,
            id: 'zgbAWP',
          },
        ],
        barsNumber: 2,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 14.673435431414203,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'SuperSawtooth',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
