import React from 'react';
import './index.css';
import {useEffect} from 'react';

export default function PrivacyPolicyChi() {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

  return (
      <div className="terms-container">
          <div className="terms">
          <h1 id="棒！音乐隐私政策">棒！音乐隐私政策</h1>
<p>更新时间：2023年3月16日
生效时间：2022年10月31日</p>
<p>“棒！音乐”（部分平台又称“棒音乐”）的运营主体北京棒棒棒科技有限公司（以下简称“我们”）深知个人信息对您的重要性，为了更好的保护您的个人信息，我们将使用符合业界标准的安全防护措施保护您提供的个人信息。鉴此，我们制定《棒！音乐隐私政策》并提醒您：
在注册并使用“棒！音乐”（部分平台又称“棒音乐”）各项产品/或服务前，请您务必仔细阅读并充分理解本隐私政策的全部内容。一旦您点击同意《棒！音乐隐私政策》并使用“棒！音乐”（包括但不限于注册用户或使用我们的产品/或服务等），即表示您已充分理解并同意本隐私政策，并同意我们按照本隐私政策使用和处理您的相关信息。</p>
<h2 id="一、我们保护您的隐私信息内容">一、我们保护您的隐私信息内容</h2>
<p>1.我们保护您的隐私信息，是指我们依据法律法规以及遵循正当、合法、必要的原则而收集和使用您在使用服务过程中主动提供或由于产品/或服务需要而产生的个人信息，包括但不限于您个人的基本信息、联系方式、邮箱以及我们通过您和第三方授权收集及存储的您个人信息等内容。您将通过信息录入方式向我们提供上述隐私信息，未经您的允许，我们保证绝不向任何第三方透漏您的任何隐私信息。</p>
<p>2.我们会不断改进我们的产品和服务及推出新的业务功能，可能需要收集您的最新的个人信息或变更个人信息使用目的或方式。如果某一需要收集您的个人信息的功能或产品/或服务未能在本隐私政策中予以说明的，我们会通过更新本隐私政策、页面提示、弹窗、网站公告等方式另行向您说明该信息收集的目的、内容、使用方式和范围，并为您提供自主选择同意的方式，且在征得您明示同意后收集。</p>
<h2 id="二、不属于本隐私政策保护的隐私信息内容">二、不属于本隐私政策保护的隐私信息内容</h2>
<p>1.您在向我们提供隐私信息之前已向第三方发布的信息；</p>
<p>2.非因我们过错，已被第三方或公众所知晓的信息；</p>
<p>3.在您向我们提供隐私信息前，我们从合法渠道已经获知的信息；</p>
<p>4.您对隐私信息发布没有限制且可以公开的信息。</p>
<h2 id="三、我们收集的信息">三、我们收集的信息</h2>
<p>我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。具体包括如下：</p>
<h3 id="1您在使用我们服务时主动提供的信息">1.您在使用我们服务时主动提供的信息</h3>
<p>1.1您在注册账户时填写的信息。例如：您在注册过程中输入的手机号码。</p>
<p>1.2您在使用服务时上传的信息。例如：使用过程中上传的头像、分享的照片、绑定的微信账号的昵称、手机号。</p>
<p>1.3您通过我们客服或参加我们举办的活动所提交的信息。例如：您通过我们线上活动时填写的调查问卷中可能包含您的姓名、电话、家庭住址等信息。</p>
<p>1.4我们的部分服务可能需要您提供特定的个人敏感信息来实现特定功能。若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其他功能。若您主动提供您的个人敏感信息，即表示您同意我们按本政策所述目的和方式来处理并保护您的个人敏感信息。</p>
<h3 id="2我们在您使用服务时获取的信息">2.我们在您使用服务时获取的信息</h3>
<p>2.1日志信息。当您使用我们的服务时，我们可能会自动收集相关信息并存储为操作日志、服务日志信息（您在使用我们的服务时查看服务提示、服务故障等信息）</p>
<p>2.2设备信息。例如：设备型号、操作系统版本、唯一设备标识符、浏览器型号、网卡地址等信息。为维护我们服务的正常运行，满足数据分析需要以改进及优化我们的服务体验，并保障你的帐号安全，我们会收集您的设备信息。</p>
<p>2.3软件信息。例如：软件的版本号、浏览器类型。为确保操作环境的安全或提供服务所需，我们会收集有关您使用的移动应用和其他软件信息。</p>
<p>2.4 IP地址。例如：您访问我们的棒！音乐PC端或移动应用程序的IP地址等信息。</p>
<p>2.5其他用户分享的信息中含有您的信息。例如，其他用户发布的照片或者分享的视频中可能包含您的信息。</p>
<p>2.6后台状态信息。例如：APP的进程信息、设备IMEI、设备MAC地址。为判断当前进行是否为主进程并满足其余数据分析需要，我们会在后台状态下获取您的设备信息。</p>
<h3 id="3我们获取的设备权限">3.我们获取的设备权限</h3>
<p>3.1为向您提供优质的产品及服务，我们可能会调用您设备上的一些权限。在您使用相应功能时，我们会以弹窗方式提醒您，询问您是否授权。您可以在客户端或设备的设置功能中选择关闭部分或全部权限。在不同设备中，上述权限的开启和关闭方式可能有所不同，具体请参考设备及系统开发方的说明或指引。</p>
<h2 id="四、我们如何保护您的隐私信息">四、我们如何保护您的隐私信息</h2>
<p>1.我们努力为用户的个人信息提供安全保障，以防止用户个人信息的非法使用、未经授权的访问和披露、泄漏、损坏和丢失。我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术（例如SSL）、加密存储等手段来保护你的个人信息；建立受信赖的保护机制防止个人信息遭到恶意攻击；建立访问控制机制，遵守最小权限原则，确保只有授权人员才可访问个人信息；开展安全和个人信息保护培训课程，加强员工对于保护个人信息保护的认识。</p>
<p>2.我们有行业先进的以数据为核心的数据安全管理体系，从管理机构、组织建设、制度体系、产品技术等方面多维度提升整个系统的安全性。我们建立专门的管理制度、流程和组织以保障个人信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。</p>
<p>3.我们会采取合理可行的措施，尽力避免收集无关的个人信息。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络我们，以便我们根据您的申请采取相应措施。</p>
<p>4.请注意，您在使用我们产品/或服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息，请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。</p>
<h2 id="五、我们对您的隐私信息的使用方式">五、我们对您的隐私信息的使用方式</h2>
<h3 id="（一）为了向您提供产品或服务而进行的使用">（一）为了向您提供产品/或服务而进行的使用</h3>
<p>1.我们严格遵守法律法规的规定以及用户的约定，将收集的信息用于以下用途，若我们超出以下用途使用您的信息，我们将再次向您进行说明，并征得您的同意。</p>
<p>1.1向您提供服务的使用方式包括如下：</p>
<p>1.1.1满足您的个性化需求。例如，头像、昵称自定义</p>
<p>1.1.2安全保障。例如，我们将您的信息用于身份验证、安全防范、反诈骗检测、存档备份、客户的安全服务等用途。</p>
<p>1.1.3评估、优化我们的产品及服务。邀请您参与有关我们服务的调查。为了让您有更好的体验、改善我们的服务或经您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某些服务所收集的信息用于我们的其他服务。例如，将您在使用我们某项服务时的信息，用于用户研究分析与统计等服务。</p>
<p>2.为了确保服务的安全，帮助我们更好地了解我们应用程序的运行情况，我们可能记录相关信息，例如，您使用应用程序的频率、故障信息、总体使用情况、性能数据以及应用程序的来源。我们不会将我们存储在分析软件中的信息与您在应用程序中提供的个人身份信息相结合。</p>
<p>3.我们除了可以将保密信息透露给我们工作人员、与我们签署类似隐私政策协议的合作方或合同签约者之外，不得向任何人透露隐私信息。</p>
<p>4.我们承诺仅把隐私信息用于为您提供相应服务时使用，且不得用于其他目的。</p>
<p>5.我们保证将您的隐私信息以软件、数据或数据库形式传送至我们所有的计算机系统。</p>
<h3 id="（二）我们分享的信息">（二）我们分享的信息</h3>
<p>1.我们遵照法律法规的规定，对信息的分享进行严格的限制，例如：</p>
<p>1.1经您事先同意，我们可能与第三方分享您的个人信息；</p>
<p>1.2若我们需要向第三方合作伙伴等分享已经匿名化或去标识化处理后的信息，我们会要求其严格遵守我们关于数据隐私保护的措施与要求，包括但不限于根据数据保护协议、承诺书及相关数据处理政策进行处理，避免识别出个人身份，保障隐私安全。该等分享将主要用于以下用途：</p>
<p>1.2.1向您提供我们的服务；</p>
<p>1.2.2实现“我们如何使用收集的信息”部分所述目的；</p>
<p>1.2.3履行我们在本政策中的义务和行使我们的权利；</p>
<p>1.2.4理解、维护和改善我们的服务；</p>
<p>1.2.5我们不会向任何第三方分享可用于识别您个人身份的信息（例如您的姓名或电子邮件地址），除非您明确授权。</p>
<p>2.我们会将所收集到的信息用于我们为提高服务质量及丰富服务内容而进行的大数据分析， 例如，我们将收集到的信息用于分析用户年龄、地域、学历分布等，以便我们了解用户，为用户提供更好的服务项目。</p>
<p>3.我们可能向您发送的信息，您在使用我们的服务时，我们可能向您发送电子邮件、短信、咨询、通知或需获得您授权的信息提示等。</p>
<p>4.与服务有关的公告。我们可能在必要时（例如，因系统维护而暂停某一项服务时候）向您发出与服务有关的公告，您可能无法取消与这些服务有关、性质不属于广告的广告。</p>
<p>5.存储信息的地点和期限：</p>
<p>5.1存储信息的地点。我们遵守法律的规定，将境内收集的用户个人信息存储于境内；</p>
<p>5.2存储信息的期限。一般而言，我们仅为实现目的所必需的最短时间保留您的个人信息。但在下列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间：</p>
<p>5.2.1为遵守适用的法律法规等有关规定；</p>
<p>5.2.2为遵守法院判决、裁定或其他法律程序的规定；</p>
<p>5.2.3为遵守相关政府机关或法定授权组织的要求；</p>
<p>5.2.4我们有理由确信需要遵守法律法规等有关规定；</p>
<p>5.2.5为执行相关服务协议或本政策、维护社会公共利益，为保障您的权益、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。</p>
<p>6.当我们的产品/或服务发生停止运营的情形时，我们将采取例如，推送通知、公告等形式通知您，并在合理的期限内删除或匿名化处理您的个人信息。</p>
<h2 id="六、我们如何使用cookie、标识符及相关技术">六、我们如何使用Cookie、标识符及相关技术</h2>
<p>1.我们或我们的第三方合作伙伴，可能通过放置安全的Cookie、标识符（为保障服务安全，我们会通过设备启动时间、系统版本、设备型号、物理内存、硬盘容量、系统更新时间、运营商、时区、国家、语言、IP地址、OAID、广告标识符（例如IDFA、Android_ID）等信息来标识设备或应用。我们会严格要求第三方合作伙伴遵守本政策的相关规定。</p>
<p>2.我们产品及服务中某些功能的技术模块由第三方SDK协助提供，关于我们产品及服务客户端中接入的第三方SDK搜集和使用用户信息的具体情况如下：</p>
<p>2.1微信SDK</p>
<p>2.1.1应用场景：微信授权登录、微信好友和微信朋友圈分享功能、微信支付。</p>
<p>2.1.2收集个人信息的类型：设备信息、本机号码。</p>
<p>2.1.3第三方SDK提供方：腾讯。</p>
<p>2.1.4隐私政策链接：
	<a href="https://privacy.qq.com/">https://privacy.qq.com/</a></p>
<p>2.2支付宝SDK</p>
<p>2.2.1应用场景：用于提供支付宝支付。</p>
<p>2.2.2收集个人信息的类型：设备信息、本机号码。</p>
<p>2.2.3第三方SDK提供方：蚂蚁集团。</p>
<p>2.2.4隐私政策链接：
	<a href="https://render.alipay.com/p/c/k2cx0tg8">https://render.alipay.com/p/c/k2cx0tg8</a></p>
<p>2.3 数数科技 SDK</p>
<p>2.3.1应用场景：设备标识（如Android_ID）、辅助记录、设备崩溃信息、数据分析、A/B测试。</p>
<p>2.3.2收集个人信息的类型：设备信息、应用信息、其他信息（运营商信息、设备时区  【Android】无线网SSID名称、WIFI路由器MAC地址、设备的mac地址、设备IMEI）。</p>
<p>2.3.3第三方SDK提供方：数数科技。</p>
<p>2.3.4隐私政策链接：
	<a href="https://docs.thinkingdata.jp/ta-manual/latest/user_guide/privacy_policy.html">https://docs.thinkingdata.jp/ta-manual/latest/user_guide/privacy_policy.html</a>
</p>
<h2 id="七、您如何管理自己的信息">七、您如何管理自己的信息</h2>
<p>1.您可以在使用我们服务的过程中，访问、修改您提供的注册信息和其他个人信息，也可按照通知指引与我们联系。您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务。例如，若您在使用地理位置相关服务时，希望停止分享您的地理位置信息，您可通过手机定位关闭功能、软硬件服务商及通讯服务提供商的关闭方式停止分享，建议您仔细阅读相关指引。</p>
<p>2.您可以选择注销/删除账号，账号被注销/删除后将不可回复，您的信息将被删除，建议您做好备份。如果您确定要注销/删除账号，按照app上的提示，通过“个人中心-设置-注销账号”即可操作注销账号。</p>
<p>3.我们将按照本政策所述，仅为实现我们产品/或服务的功能，收集、使用您的信息。</p>
<h2 id="八、本隐私政策如何更新">八、本隐私政策如何更新</h2>
<p>1.为了给您提供更好的服务，我们会根据产品/或服务的更新情况及法律法规的相关要求更新本《隐私政策》的条款。更新后的隐私政策将公布在“棒！音乐”上，重大变更将以公告、用户通知等合适的形式告知用户。本隐私政策所指的重大变更包括：</p>
<p>1.1我们的产品/或服务模式发生重大变化，如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等。</p>
<p>1.2个人信息共享、转让或公开披露的主要对象发生变化。</p>
<p>1.3您参与个人信息处理方面的权利及其行使方式发生重大变化。</p>
<p>1.4我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时。</p>
<p>2.当您使用“棒！音乐”时，您可以随时审查我们的隐私政策，以便您了解其修改。您可以在本隐私通知的顶部的“最近更新”的日期处查看本隐私政策的最新更新日期。</p>
<h2 id="九、联系我们">九、联系我们</h2>
<p>如您对本隐私政策内容或使用我们的产品或服务时，遇到与隐私保护相关事宜有任何疑问或进行咨询或投诉时，您可以向我们开通的【
	<a href="mailto:hi@bonbonbongame.com">hi@bonbonbongame.com</a>】邮箱发送邮件与我们联系。我们会在收到您的意见及建议后15个工作日内尽快向您回复。
</p>

          </div>
      </div>
  )
        
}