import { SongType } from "../player/utils";

export const SamplerViolinPlucking: SongType = {
  id: '663clrpf9S',
  beat: 4,
  tempo: 140,
  tonic: 'c',
  scale: 'chromatic',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: 'hyj-BU',
        items: [
          {
            S: 0,
            L: 18,
            y: 12,
            id: 'PNShIf',
          },
          {
            S: 36,
            L: 18,
            y: 20,
            id: 'oeAKYF',
          },
          {
            S: 18,
            L: 18,
            y: 15,
            id: 'yrI0jp',
          },
          {
            S: 54,
            L: 18,
            y: 24,
            id: 'JJrPqo',
          },
          {
            S: 72,
            L: 18,
            y: 27,
            id: 'fEUKHA',
          },
          {
            S: 90,
            L: 18,
            y: 20,
            id: 'xmujX9',
          },
          {
            S: 108,
            L: 18,
            y: 24,
            id: 'LssUlN',
          },
          {
            S: 126,
            L: 18,
            y: 27,
            id: 'mgG1S3',
          },
          {
            S: 144,
            L: 18,
            y: 13,
            id: 'pCirK3',
          },
          {
            S: 162,
            L: 18,
            y: 16,
            id: 'nAvVzy',
          },
          {
            S: 180,
            L: 18,
            y: 20,
            id: 'hLb4ag',
          },
          {
            S: 198,
            L: 18,
            y: 10,
            id: 'g8EYNX',
          },
          {
            S: 216,
            L: 18,
            y: 13,
            id: 'sGc6mp',
          },
          {
            S: 234,
            L: 18,
            y: 20,
            id: 'b7XK1P',
          },
          {
            S: 252,
            L: 18,
            y: 16,
            id: 'p7Ytiq',
          },
          {
            S: 270,
            L: 18,
            y: 20,
            id: 'gVpkM5',
          },
          {
            S: 288,
            L: 18,
            y: 12,
            id: 'hBUO6J',
          },
          {
            S: 306,
            L: 18,
            y: 15,
            id: 'EUc2gs',
          },
          {
            S: 324,
            L: 18,
            y: 20,
            id: 'yA71bU',
          },
          {
            S: 342,
            L: 18,
            y: 24,
            id: 'rjFAAF',
          },
          {
            S: 360,
            L: 18,
            y: 27,
            id: 'QJvFhF',
          },
          {
            S: 378,
            L: 18,
            y: 20,
            id: 'Kny1o-',
          },
          {
            S: 396,
            L: 18,
            y: 24,
            id: 'x6W86X',
          },
          {
            S: 414,
            L: 18,
            y: 27,
            id: 'IrENi3',
          },
          {
            S: 432,
            L: 18,
            y: 13,
            id: 'vW-B5M',
          },
          {
            S: 450,
            L: 18,
            y: 16,
            id: 'qB496q',
          },
          {
            S: 468,
            L: 18,
            y: 20,
            id: 'qkvgDe',
          },
          {
            S: 486,
            L: 18,
            y: 20,
            id: 'rPR0C2',
          },
          {
            S: 504,
            L: 18,
            y: 25,
            id: 'aFN0PN',
          },
          {
            S: 522,
            L: 18,
            y: 28,
            id: '32CuQk',
          },
          {
            S: 540,
            L: 36,
            y: 32,
            id: '4OZnfU',
          },
        ],
        barsNumber: 2,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'ViolinPlucking',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
