import { ResourceName, SongType } from '../player/utils';
import { Sampler808DrumMachine } from './808DrumMachine';
import { SamplerAcousticDrum } from './acousticDrum';
import { SamplerBirdie } from './birdie';
import { SamplerClarinet } from './clarinet';
import { SamplerDrumsKinder } from './drumsKinder';
import { SamplerElectricBass } from './electricBass';
import { SamplerGuitar } from './guitar';
import { SamplerGuzheng } from './guzheng';
import { SamplerMarimba } from './marimba';
import { SamplerMelodica } from './melodica';
import { SamplerPiano } from './piano';
import { SamplerPianoBass } from './pianoBass';
import { SamplerRecorder } from './recorder';
import { SamplerSawtoothBass } from './sawtoothBass';
import { SamplerSquare } from './square';
import { SamplerSquareBass } from './squareBass';
import { SamplerSummer } from './summer';
import { SamplerSuperSawtooth } from './superSawtooth';
import { SamplerUkulele } from './ukulele';
import { SamplerViolinPlaying } from './violinPlaying';
import { SamplerViolinPlayingBass } from './violinPlayingBass';
import { SamplerViolinPlucking } from './violinPlucking';

export const ResourceSamplers: Record<ResourceName, SongType> = {
  Summer: SamplerSummer,
  AcousticDrum: SamplerAcousticDrum,
  '808DrumMachine': Sampler808DrumMachine,
  DrumsKinder: SamplerDrumsKinder,
  ElectricBass: SamplerElectricBass,
  SquareBass: SamplerSquareBass,
  ViolinPlayingBass: SamplerViolinPlayingBass,
  PianoBass: SamplerPianoBass,
  SawtoothBass: SamplerSawtoothBass,
  Piano: SamplerPiano,
  Marimba: SamplerMarimba,
  Clarinet: SamplerClarinet,
  Square: SamplerSquare,
  ViolinPlucking: SamplerViolinPlucking,
  ViolinPlaying: SamplerViolinPlaying,
  Guitar: SamplerGuitar,
  Ukulele: SamplerUkulele,
  Guzheng: SamplerGuzheng,
  Melodica: SamplerMelodica,
  Recorder: SamplerRecorder,
  SuperSawtooth: SamplerSuperSawtooth,
  Birdie: SamplerBirdie,
};
