import { SongType } from "../player/utils";

export const SamplerAcousticDrum: SongType = {
  id: 'n_zxHHQM_Z',
  beat: 4,
  tempo: 135,
  tonic: 'a',
  scale: 'chromatic',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [
      {
        id: 'zbcAhd',
        items: [
          {
            S: 0,
            L: 18,
            y: 11,
            id: 'v4bGi4',
          },
          {
            S: 72,
            L: 18,
            y: 9,
            id: 'ogLu4F',
          },
          {
            S: 216,
            L: 18,
            y: 9,
            id: 'OeVrXG',
          },
          {
            S: 180,
            L: 18,
            y: 11,
            id: 'Tl8V0m',
          },
          {
            S: 126,
            L: 18,
            y: 11,
            id: 'H1JVFE',
          },
          {
            S: 144,
            L: 18,
            y: 11,
            id: 'QdpfNV',
          },
          {
            S: 252,
            L: 18,
            y: 11,
            id: 'jYQv62',
          },
          {
            S: 0,
            L: 18,
            y: 0,
            id: 'OPAYXg',
          },
          {
            S: 72,
            L: 18,
            y: 0,
            id: 'thqrvt',
          },
          {
            S: 144,
            L: 18,
            y: 0,
            id: 'hcSehc',
          },
          {
            S: 216,
            L: 18,
            y: 0,
            id: 'UtJ6qg',
          },
          {
            S: 0,
            L: 18,
            y: 7,
            id: 'd8PaJi',
          },
          {
            S: 36,
            L: 18,
            y: 7,
            id: 'qloCUI',
          },
          {
            S: 72,
            L: 18,
            y: 6,
            id: 'NNzzlu',
          },
          {
            S: 144,
            L: 18,
            y: 7,
            id: '912LLQ',
          },
          {
            S: 180,
            L: 18,
            y: 7,
            id: 'c7tjAs',
          },
          {
            S: 216,
            L: 18,
            y: 6,
            id: 'p87WH9',
          },
          {
            S: 252,
            L: 18,
            y: 7,
            id: 'xdd92a',
          },
          {
            S: 270,
            L: 18,
            y: 7,
            id: 'MKZ9hz',
          },
          {
            S: 162,
            L: 18,
            y: 7,
            id: 'bk-kcP',
          },
          {
            S: 108,
            L: 18,
            y: 7,
            id: 'zhRWlV',
          },
          {
            S: 72,
            L: 18,
            y: 2,
            id: 'uZX4dM',
          },
          {
            S: 216,
            L: 18,
            y: 2,
            id: 'eRS9F5',
          },
          {
            S: 126,
            L: 18,
            y: 2,
            id: '7BZQ54',
          },
          {
            S: 180,
            L: 18,
            y: 2,
            id: 'gyI-dM',
          },
          {
            S: 0,
            L: 18,
            y: 10,
            id: 'Mmv3sZ',
          },
          {
            S: 144,
            L: 18,
            y: 10,
            id: 'eNH4Dm',
          },
          {
            S: 252,
            L: 18,
            y: 10,
            id: 'fZ7EKM',
          },
          {
            S: 18,
            L: 18,
            y: 8,
            id: 'gEiTIW',
          },
          {
            S: 54,
            L: 18,
            y: 8,
            id: 'KB7a87',
          },
          {
            S: 90,
            L: 18,
            y: 8,
            id: 'Izh_U3',
          },
          {
            S: 126,
            L: 18,
            y: 8,
            id: 'X08h-Y',
          },
          {
            S: 198,
            L: 18,
            y: 8,
            id: 'lZkOyG',
          },
          {
            S: 234,
            L: 18,
            y: 8,
            id: 'lfP65r',
          },
        ],
        barsNumber: 1,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'drums',
      },
    ],
    melody: [],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -19,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 13.1555121196018,
    },
    bass: {
      value: -6,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 9.989913959315244,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Birdie',
    chord: 'Square',
    bass: 'SawtoothBass',
  },
};
