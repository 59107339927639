import { SongType } from "../player/utils";

export const SamplerElectricBass: SongType = {
  id: 'WE4v7dYN48',
  beat: 4,
  tempo: 148,
  tonic: 'e',
  scale: 'chromatic',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [],
    chord: [],
    bass: [
      {
        id: 'o6I0L8',
        items: [
          {
            S: 0,
            L: 99,
            y: 36,
            id: 'dBAKfX',
          },
          {
            S: 108,
            L: 36,
            y: 26,
            id: 'oZhThg',
          },
          {
            S: 252,
            L: 27,
            y: 36,
            id: '0QrAUK',
          },
          {
            S: 324,
            L: 27,
            y: 36,
            id: 'XnLqds',
          },
          {
            S: 396,
            L: 36,
            y: 26,
            id: 'Pxx84G',
          },
          {
            S: 432,
            L: 36,
            y: 24,
            id: 'fsJSSG',
          },
          {
            S: 468,
            L: 27,
            y: 36,
            id: '_bWhgS',
          },
          {
            S: 504,
            L: 27,
            y: 26,
            id: 'KLFyj6',
          },
          {
            S: 540,
            L: 36,
            y: 33,
            id: 'U53zC2',
          },
          {
            S: 576,
            L: 72,
            y: 31,
            id: 'eOU1Oe',
          },
          {
            S: 720,
            L: 72,
            y: 19,
            id: 'lnx0Nx',
          },
          {
            S: 684,
            L: 36,
            y: 21,
            id: 'zP_ITX',
          },
          {
            S: 828,
            L: 9,
            y: 19,
            id: 'CYb4eJ',
          },
          {
            S: 837,
            L: 54,
            y: 17,
            id: 'jOKHW7',
          },
          {
            S: 900,
            L: 63,
            y: 17,
            id: 'pvSeJ_',
          },
          {
            S: 972,
            L: 36,
            y: 17,
            id: 'sRaGJK',
          },
          {
            S: 1008,
            L: 36,
            y: 19,
            id: 'T1Lg7L',
          },
          {
            S: 1080,
            L: 63,
            y: 26,
            id: 'yp9-aN',
          },
          {
            S: 1044,
            L: 27,
            y: 21,
            id: 'wPDIWw',
          },
          {
            S: 144,
            L: 108,
            y: 24,
            id: 'dI4JxO',
          },
        ],
        barsNumber: 4,
        gridNumber: 8,
        barIndex: 0,
        trackName: 'bass',
      },
    ],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Piano',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
