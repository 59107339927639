import { SongType } from "../player/utils";

export const SamplerSawtoothBass: SongType = {
  id: 'esrB4GQcvG',
  beat: 4,
  tempo: 144,
  tonic: 'a',
  scale: 'chromatic',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [],
    chord: [],
    bass: [
      {
        id: 'DkMszD',
        items: [
          {
            S: 0,
            L: 108,
            y: 24,
            id: 'RduQqh',
          },
          {
            S: 108,
            L: 180,
            y: 23,
            id: 'jhNuxA',
          },
          {
            S: 288,
            L: 108,
            y: 21,
            id: 'hPejrw',
          },
          {
            S: 576,
            L: 108,
            y: 24,
            id: 'v-rppp',
          },
          {
            S: 864,
            L: 108,
            y: 19,
            id: 'B0-N2H',
          },
          {
            S: 396,
            L: 180,
            y: 23,
            id: 'hBOIfN',
          },
          {
            S: 684,
            L: 180,
            y: 23,
            id: 'GVa8lG',
          },
          {
            S: 972,
            L: 108,
            y: 21,
            id: 'RMJ9r1',
          },
          {
            S: 1080,
            L: 72,
            y: 26,
            id: '4xl95g',
          },
        ],
        barsNumber: 4,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'bass',
      },
    ],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -6,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 9.989913959315244,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Piano',
    chord: 'Piano',
    bass: 'SawtoothBass',
  },
};
