import { SongType } from "../player/utils";

export const SamplerMelodica: SongType = {
  id: 'g7ZfYlo4gy',
  beat: 4,
  tempo: 120,
  tonic: 'c',
  scale: 'major',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: '-ZglAO',
        items: [
          {
            S: 0,
            L: 18,
            y: 13,
            id: '5Q0crc',
          },
          {
            S: 36,
            L: 18,
            y: 8,
            id: 'wzKIn_',
          },
          {
            S: 72,
            L: 18,
            y: 9,
            id: '6tdteP',
          },
          {
            S: 108,
            L: 18,
            y: 10,
            id: '_HZUT8',
          },
          {
            S: 144,
            L: 90,
            y: 13,
            id: 'W5XGPY',
          },
          {
            S: 288,
            L: 18,
            y: 13,
            id: 'a2vjqe',
          },
          {
            S: 324,
            L: 18,
            y: 8,
            id: 'ljXz2L',
          },
          {
            S: 360,
            L: 18,
            y: 9,
            id: 'CNbjVC',
          },
          {
            S: 396,
            L: 18,
            y: 10,
            id: 'od_Djt',
          },
          {
            S: 432,
            L: 90,
            y: 12,
            id: '4M72ve',
          },
          {
            S: 756,
            L: 18,
            y: 6,
            id: 'YJt_G3',
          },
          {
            S: 792,
            L: 18,
            y: 6,
            id: 'HlsAT2',
          },
          {
            S: 864,
            L: 18,
            y: 5,
            id: 'TB1ErQ',
          },
          {
            S: 900,
            L: 18,
            y: 6,
            id: 'YJZC-s',
          },
          {
            S: 576,
            L: 36,
            y: 12,
            id: 'ycX1iV',
          },
          {
            S: 684,
            L: 18,
            y: 9,
            id: '1joiUR',
          },
          {
            S: 720,
            L: 18,
            y: 6,
            id: 'ER6m3z',
          },
          {
            S: 612,
            L: 36,
            y: 7,
            id: '7HPQ3O',
          },
          {
            S: 648,
            L: 18,
            y: 8,
            id: 'usDqqQ',
          },
          {
            S: 936,
            L: 18,
            y: 7,
            id: 'mK2psZ',
          },
          {
            S: 972,
            L: 18,
            y: 9,
            id: 'V2k5bb',
          },
          {
            S: 1008,
            L: 144,
            y: 10,
            id: 'h-x0lL',
          },
        ],
        barsNumber: 4,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'DrumsKinder',
    melody: 'Melodica',
    chord: 'Piano',
    bass: 'ViolinPlayingBass',
  },
};
