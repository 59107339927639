import { SongType } from "../player/utils";

export const SamplerPiano: SongType = {
  id: '4Wvw-oz37D',
  beat: 4,
  tempo: 120,
  tonic: 'c',
  scale: 'chromatic',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: 'fsl54t',
        items: [
          {
            S: 0,
            L: 18,
            y: 21,
            id: 'tkQSbN',
          },
          {
            S: 18,
            L: 18,
            y: 21,
            id: 'hnyuT6',
          },
          {
            S: 36,
            L: 18,
            y: 21,
            id: 'xTgAyO',
          },
          {
            S: 54,
            L: 18,
            y: 21,
            id: 'pyCwoC',
          },
          {
            S: 72,
            L: 18,
            y: 21,
            id: '8uVEUA',
          },
          {
            S: 108,
            L: 18,
            y: 21,
            id: 'mQ_V4f',
          },
          {
            S: 126,
            L: 18,
            y: 21,
            id: 'Yqq2hR',
          },
          {
            S: 90,
            L: 18,
            y: 21,
            id: 'Zgl4kq',
          },
          {
            S: 162,
            L: 18,
            y: 21,
            id: 'uyJxJ4',
          },
          {
            S: 180,
            L: 18,
            y: 19,
            id: 'FRXkIr',
          },
          {
            S: 198,
            L: 18,
            y: 21,
            id: 'ffgnJn',
          },
          {
            S: 216,
            L: 18,
            y: 24,
            id: 'ZwsNc_',
          },
          {
            S: 234,
            L: 18,
            y: 28,
            id: 'o2937h',
          },
          {
            S: 252,
            L: 18,
            y: 33,
            id: 'UXSVXq',
          },
          {
            S: 144,
            L: 18,
            y: 21,
            id: 'G6lcwK',
          },
          {
            S: 270,
            L: 18,
            y: 32,
            id: '-dV7Cn',
          },
          {
            S: 288,
            L: 18,
            y: 31,
            id: 'Mhv6EL',
          },
          {
            S: 306,
            L: 18,
            y: 31,
            id: 'ZcU1lJ',
          },
          {
            S: 324,
            L: 18,
            y: 31,
            id: '-xgSuU',
          },
          {
            S: 342,
            L: 18,
            y: 31,
            id: 'md4qzX',
          },
          {
            S: 360,
            L: 18,
            y: 31,
            id: 'efS-Y-',
          },
          {
            S: 378,
            L: 18,
            y: 31,
            id: 'Ec4cmh',
          },
          {
            S: 396,
            L: 18,
            y: 33,
            id: '0oIHwu',
          },
          {
            S: 414,
            L: 18,
            y: 28,
            id: 'RZ-T7H',
          },
          {
            S: 432,
            L: 18,
            y: 24,
            id: '9ZQ2Bj',
          },
          {
            S: 450,
            L: 18,
            y: 21,
            id: 'OcX7oP',
          },
          {
            S: 468,
            L: 18,
            y: 16,
            id: 'u0Cypn',
          },
          {
            S: 486,
            L: 18,
            y: 20,
            id: 'MWWnpt',
          },
          {
            S: 504,
            L: 18,
            y: 19,
            id: 'g2DJIS',
          },
          {
            S: 522,
            L: 18,
            y: 19,
            id: 'kTnAdd',
          },
          {
            S: 540,
            L: 18,
            y: 19,
            id: '_FEIfZ',
          },
          {
            S: 558,
            L: 18,
            y: 19,
            id: 'szyz2J',
          },
          {
            S: 576,
            L: 18,
            y: 19,
            id: 'KECyyZ',
          },
          {
            S: 594,
            L: 18,
            y: 19,
            id: 'dY3y57',
          },
          {
            S: 612,
            L: 18,
            y: 19,
            id: '12HAxC',
          },
          {
            S: 648,
            L: 18,
            y: 21,
            id: '_UOhyk',
          },
          {
            S: 666,
            L: 18,
            y: 16,
            id: '7_Adhc',
          },
          {
            S: 684,
            L: 18,
            y: 12,
            id: 'H9uDXa',
          },
          {
            S: 702,
            L: 36,
            y: 7,
            id: 'iekVXO',
          },
          {
            S: 738,
            L: 18,
            y: 9,
            id: 'rKlVmp',
          },
          {
            S: 756,
            L: 18,
            y: 11,
            id: 'a0ZFhT',
          },
          {
            S: 774,
            L: 36,
            y: 17,
            id: 'MdaPHP',
          },
          {
            S: 810,
            L: 54,
            y: 16,
            id: 'OFrAnE',
          },
          {
            S: 630,
            L: 18,
            y: 19,
            id: 't_cdWi',
          },
        ],
        barsNumber: 4,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Piano',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
