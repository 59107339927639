import { SongType } from "../player/utils";

export const SamplerMarimba: SongType = {
  id: 'oUzSkE3xTM',
  beat: 4,
  tempo: 90,
  tonic: 'e',
  scale: 'major',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: 'cCP4ut',
        items: [
          {
            S: 0,
            L: 18,
            y: 16,
            id: '6IuGf1',
          },
          {
            S: 96,
            L: 18,
            y: 7,
            id: 'PE9cq1',
          },
          {
            S: 24,
            L: 24,
            y: 14,
            id: '3y4img',
          },
          {
            S: 48,
            L: 24,
            y: 11,
            id: 'iLp4mV',
          },
          {
            S: 72,
            L: 24,
            y: 9,
            id: 'oJgxak',
          },
          {
            S: 120,
            L: 24,
            y: 6,
            id: 'KcEea_',
          },
          {
            S: 144,
            L: 24,
            y: 5,
            id: 'RhygMt',
          },
          {
            S: 168,
            L: 24,
            y: 8,
            id: '6vbRzh',
          },
          {
            S: 192,
            L: 24,
            y: 10,
            id: 'lrW4V3',
          },
          {
            S: 216,
            L: 24,
            y: 12,
            id: 'xlm433',
          },
          {
            S: 240,
            L: 24,
            y: 15,
            id: 'kQTxuL',
          },
          {
            S: 264,
            L: 24,
            y: 17,
            id: 'EN6iF4',
          },
          {
            S: 288,
            L: 24,
            y: 18,
            id: 'FtUMp0',
          },
          {
            S: 312,
            L: 24,
            y: 16,
            id: 'qZxXxI',
          },
          {
            S: 336,
            L: 24,
            y: 13,
            id: 'JK4eVh',
          },
          {
            S: 360,
            L: 24,
            y: 11,
            id: 'EpadVE',
          },
          {
            S: 384,
            L: 24,
            y: 9,
            id: '0LfKvt',
          },
          {
            S: 408,
            L: 24,
            y: 8,
            id: 'Qpdjj4',
          },
          {
            S: 432,
            L: 24,
            y: 7,
            id: '8yfO-K',
          },
          {
            S: 456,
            L: 24,
            y: 10,
            id: 'vIYiEY',
          },
          {
            S: 480,
            L: 24,
            y: 12,
            id: 'uouw9v',
          },
          {
            S: 504,
            L: 24,
            y: 14,
            id: 'NRrsgo',
          },
          {
            S: 528,
            L: 24,
            y: 17,
            id: 'heGYXK',
          },
          {
            S: 552,
            L: 24,
            y: 19,
            id: 'rAtbmR',
          },
          {
            S: 576,
            L: 144,
            y: 20,
            id: 'fWDfmM',
          },
          {
            S: 600,
            L: 24,
            y: 18,
            id: 'mwuyVH',
          },
          {
            S: 624,
            L: 96,
            y: 16,
            id: 'q51lEm',
          },
          {
            S: 672,
            L: 24,
            y: 13,
            id: 'TNg48r',
          },
          {
            S: 696,
            L: 96,
            y: 11,
            id: '3vfPWM',
          },
          {
            S: 648,
            L: 72,
            y: 9,
            id: '-cGAo1',
          },
          {
            S: 720,
            L: 24,
            y: 6,
            id: 'gziNN_',
          },
          {
            S: 720,
            L: 144,
            y: 21,
            id: 'zq3HNc',
          },
          {
            S: 744,
            L: 24,
            y: 18,
            id: 'Ovdite',
          },
          {
            S: 768,
            L: 96,
            y: 16,
            id: 'HupcTC',
          },
          {
            S: 816,
            L: 24,
            y: 13,
            id: 'Cx-8Mr',
          },
          {
            S: 840,
            L: 24,
            y: 11,
            id: '-tsyqA',
          },
          {
            S: 792,
            L: 72,
            y: 9,
            id: '5sGe5Y',
          },
          {
            S: 888,
            L: 24,
            y: 18,
            id: 'tfLKOF',
          },
          {
            S: 912,
            L: 96,
            y: 16,
            id: 'o6iesa',
          },
          {
            S: 960,
            L: 24,
            y: 13,
            id: 'tcbSaj',
          },
          {
            S: 984,
            L: 96,
            y: 11,
            id: '2j-Fh4',
          },
          {
            S: 936,
            L: 72,
            y: 9,
            id: 'CPNsS_',
          },
          {
            S: 1008,
            L: 72,
            y: 6,
            id: 'SU46Ha',
          },
          {
            S: 1056,
            L: 96,
            y: 14,
            id: 'Iv45F1',
          },
          {
            S: 1104,
            L: 24,
            y: 11,
            id: 'z814u5',
          },
          {
            S: 1128,
            L: 24,
            y: 9,
            id: 'L60nP-',
          },
          {
            S: 1080,
            L: 72,
            y: 4,
            id: '-8T8zv',
          },
          {
            S: 864,
            L: 144,
            y: 15,
            id: 'H-YbKL',
          },
          {
            S: 1008,
            L: 144,
            y: 18,
            id: 'xCy3rT',
          },
        ],
        barsNumber: 4,
        gridNumber: 3,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 13.876444482003938,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Marimba',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
