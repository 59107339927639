import { AnimatedSprite, Texture } from 'pixi.js-legacy';
import { Draw, Player, Transport } from 'tone';
import { animalConfigs, animalsDetails, NotDrumsAnimalConfig, NoteType, resourceLoader, ResourceName, RolesNameType, TrackNamesType, throttle } from './utils';

export default function NotDrumsAnimal(
  roleName: RolesNameType,
  trackName?: TrackNamesType,
  anchor: [number, number] = [0.5, 0]
) {
  const {
    idle,
    actions,
    tap,
    resources: resourcesRange,
  } = animalConfigs[roleName] as NotDrumsAnimalConfig;

  const { resources } = animalsDetails[roleName];

  const audioPlayers = new Array(tap.audios).fill('').map(
    (_, i) => {
      return new Player(require(`../../../../assets/animals/${roleName}/audio/${i}.mp3`)).toDestination()
    }
  )

  const idleNames = new Array(idle).fill('').map((_, i) => {
    const path = `${roleName}/idle/${i}`;
    return {
      name: path,
      url: require(`../../../../assets/animals/${path}.png`),
    };
  });

  const idleTextures = () =>
    idleNames.map((texture) => Texture.from(texture.name));

  const tapTextures = new Array(tap.frames).fill('').map((_, i) => {
    const path = `${roleName}/tap/${i}`;
    return Texture.from(
      require(`../../../../assets/animals/${path}.png`)
    );
  });

  const actionTextures = actions
    .map((number, i) =>
      new Array(number).fill('').map((_, j) => {
        const path = `${roleName}/act${i + 1}/${j}`;
        return Texture.from(
          require(`../../../../assets/animals/${path}.png`)
        );
      })
    )
    .filter((item) => item.length > 0);

  const sprite = new AnimatedSprite([Texture.EMPTY]);
  sprite.interactive = true;
  sprite.buttonMode = true;
  sprite.anchor.set(anchor[0], anchor[1]);
  sprite.loop = false;
  sprite.animationSpeed = 0.4;

  let actionIndex = 0;
  let active = true;
  let range: [number, number] = [0, actions.length];
  const realActions = actions.filter((number) => number > 0);
  const { length } = realActions;

  const playNextAction = throttle<void>(() => {
    sprite.textures = actionTextures[actionIndex];
    actionIndex = (actionIndex + 1) % length;
    sprite.gotoAndPlay(0);
    sprite.animationSpeed = 0.4;
  }, 60)

  const playIdle = () => {
    if (!sprite.playing) {
      sprite.textures = idleTextures();
      sprite.animationSpeed = 0.4;
      sprite.gotoAndPlay(0);
    }
  };

  resourceLoader(idleNames).then(() => {
    sprite.textures = idleTextures();

    Transport.scheduleRepeat((time) => {
      Draw.schedule(() => {
        if (active) {
          playIdle();
        }
      }, time);
    }, '0:1:0');
  });

  const playAudio = () => {
    sprite.textures = tapTextures;
    sprite.animationSpeed = 1;
    sprite.gotoAndPlay(0);
    if (tap.audios === 0) return;
    audioPlayers[Math.floor(Math.random() * tap.audios)].start()
  };

  sprite.interactive = true;
  sprite.on('pointertap', playAudio);

  return {
    result: sprite,
    set active(value: boolean) {
      active = value;
    },
    get active() {
      return active;
    },
    playNote: (v: NoteType & { resourceName: ResourceName; }) => {
      if (
        active &&
        resources.indexOf(v.resourceName) !== -1 &&
        (!trackName || v.trackName === trackName)
      ) {
        if (resourcesRange) {
          range = resourcesRange[v.resourceName]!;
          actionIndex = Math.max(range[0], Math.min(actionIndex, range[1] - 1));
        }
        playNextAction();
      }
    }
  };
}
