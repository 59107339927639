import { SongType } from "../player/utils";

export const SamplerSquare: SongType = {
  id: 'VvxCJBkuqu',
  beat: 4,
  tempo: 82,
  tonic: 'c',
  scale: 'chromatic',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: 'to0phX',
        items: [
          {
            S: 144,
            L: 24,
            y: 32,
            id: '2EKtX_',
          },
          {
            S: 168,
            L: 24,
            y: 28,
            id: 'Iqw6kI',
          },
          {
            S: 192,
            L: 24,
            y: 24,
            id: 'gAYktS',
          },
          {
            S: 72,
            L: 24,
            y: 36,
            id: '0Cu8xz',
          },
          {
            S: 96,
            L: 24,
            y: 32,
            id: 'dOYnDh',
          },
          {
            S: 216,
            L: 24,
            y: 32,
            id: 'TrVVYM',
          },
          {
            S: 240,
            L: 24,
            y: 28,
            id: 'qcaSrT',
          },
          {
            S: 264,
            L: 24,
            y: 24,
            id: 'iF86xC',
          },
          {
            S: 288,
            L: 24,
            y: 31,
            id: 'Y0kIDZ',
          },
          {
            S: 312,
            L: 24,
            y: 27,
            id: '4DAJGV',
          },
          {
            S: 336,
            L: 24,
            y: 24,
            id: 'uJJtp4',
          },
          {
            S: 360,
            L: 24,
            y: 31,
            id: 'baTYUd',
          },
          {
            S: 384,
            L: 24,
            y: 27,
            id: '5RHLB-',
          },
          {
            S: 408,
            L: 24,
            y: 24,
            id: 'Z-AEoZ',
          },
          {
            S: 432,
            L: 24,
            y: 31,
            id: 'UFJDrd',
          },
          {
            S: 456,
            L: 24,
            y: 28,
            id: 'IUKR9k',
          },
          {
            S: 480,
            L: 24,
            y: 22,
            id: 'sjSUPN',
          },
          {
            S: 504,
            L: 24,
            y: 31,
            id: 'ZBW8A7',
          },
          {
            S: 528,
            L: 24,
            y: 28,
            id: 'fXjyI5',
          },
          {
            S: 552,
            L: 24,
            y: 24,
            id: 'oiCylN',
          },
          {
            S: 0,
            L: 72,
            y: 8,
            id: 'BTHyXt',
          },
          {
            S: 120,
            L: 24,
            y: 12,
            id: 'cnjgK0',
          },
          {
            S: 288,
            L: 72,
            y: 7,
            id: 'uvGxlO',
          },
          {
            S: 432,
            L: 48,
            y: 4,
            id: 'TRwYCp',
          },
          {
            S: 504,
            L: 24,
            y: 0,
            id: 'I_kxbM',
          },
          {
            S: 192,
            L: 24,
            y: 10,
            id: 'iROOE-',
          },
          {
            S: 264,
            L: 24,
            y: 4,
            id: 's6AAyB',
          },
          {
            S: 408,
            L: 24,
            y: 5,
            id: 'EPN9FR',
          },
          {
            S: 480,
            L: 24,
            y: 2,
            id: 'nPjjf_',
          },
          {
            S: 72,
            L: 48,
            y: 10,
            id: 'E8zeyE',
          },
          {
            S: 216,
            L: 24,
            y: 12,
            id: 'ov-Tmq',
          },
          {
            S: 144,
            L: 48,
            y: 10,
            id: 'JECPLp',
          },
          {
            S: 360,
            L: 48,
            y: 7,
            id: 'RG9N-Y',
          },
          {
            S: 0,
            L: 24,
            y: 36,
            id: 'x5A08_',
          },
          {
            S: 48,
            L: 24,
            y: 29,
            id: '1e78WD',
          },
          {
            S: 24,
            L: 24,
            y: 32,
            id: 'BShvoX',
          },
          {
            S: 120,
            L: 24,
            y: 24,
            id: '4ACwIh',
          },
          {
            S: 552,
            L: 24,
            y: 7,
            id: 'v-PYL-',
          },
          {
            S: 528,
            L: 24,
            y: 4,
            id: 'wkYyBO',
          },
          {
            S: 240,
            L: 24,
            y: 8,
            id: 'TsJYzq',
          },
        ],
        barsNumber: 2,
        gridNumber: 3,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 10,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Square',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
