import { SongType } from "../player/utils";

export const SamplerClarinet: SongType = {
  id: 'MZnBenTGL1',
  beat: 4,
  tempo: 97,
  tonic: 'c',
  scale: 'chromatic',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: 'yAZl95',
        items: [
          {
            S: 0,
            L: 18,
            y: 25,
            id: 'N5z5M8',
          },
          {
            S: 18,
            L: 18,
            y: 27,
            id: '_SJOmR',
          },
          {
            S: 36,
            L: 18,
            y: 28,
            id: 'rdkAQS',
          },
          {
            S: 54,
            L: 18,
            y: 27,
            id: 'XyVkha',
          },
          {
            S: 72,
            L: 36,
            y: 24,
            id: 'Ba7HOw',
          },
          {
            S: 144,
            L: 18,
            y: 22,
            id: 'fyJbwi',
          },
          {
            S: 162,
            L: 18,
            y: 24,
            id: 'aDnguT',
          },
          {
            S: 180,
            L: 18,
            y: 25,
            id: 'APCQss',
          },
          {
            S: 198,
            L: 18,
            y: 24,
            id: 'DwM6Ii',
          },
          {
            S: 216,
            L: 36,
            y: 20,
            id: 'C0qDm0',
          },
          {
            S: 288,
            L: 18,
            y: 19,
            id: '7r_qc4',
          },
          {
            S: 306,
            L: 18,
            y: 20,
            id: 'sZCWWj',
          },
          {
            S: 324,
            L: 18,
            y: 21,
            id: '_F9yYp',
          },
          {
            S: 342,
            L: 18,
            y: 20,
            id: '0_mJ_O',
          },
          {
            S: 360,
            L: 18,
            y: 13,
            id: 'A2lF7Q',
          },
          {
            S: 378,
            L: 18,
            y: 15,
            id: 'ahG_KU',
          },
          {
            S: 396,
            L: 18,
            y: 16,
            id: '4_Ui7O',
          },
          {
            S: 414,
            L: 18,
            y: 15,
            id: '55RzEm',
          },
          {
            S: 432,
            L: 18,
            y: 13,
            id: 'hSLc8G',
          },
          {
            S: 450,
            L: 18,
            y: 15,
            id: 'MeesGd',
          },
          {
            S: 468,
            L: 18,
            y: 16,
            id: 'E5n3kw',
          },
          {
            S: 486,
            L: 18,
            y: 15,
            id: 'btqWvC',
          },
          {
            S: 504,
            L: 72,
            y: 12,
            id: 'zaTL4L',
          },
          {
            S: 576,
            L: 18,
            y: 15,
            id: '77QUB7',
          },
          {
            S: 612,
            L: 18,
            y: 12,
            id: '1lpxoS',
          },
          {
            S: 648,
            L: 18,
            y: 13,
            id: 'AagU22',
          },
          {
            S: 684,
            L: 18,
            y: 15,
            id: 'FWdlJc',
          },
          {
            S: 720,
            L: 18,
            y: 17,
            id: 'XZjnbn',
          },
          {
            S: 756,
            L: 18,
            y: 15,
            id: 'Tt67cR',
          },
          {
            S: 828,
            L: 18,
            y: 15,
            id: 'tl97e0',
          },
          {
            S: 792,
            L: 18,
            y: 13,
            id: '5q4Oo-',
          },
          {
            S: 864,
            L: 18,
            y: 17,
            id: 'eN2HPU',
          },
          {
            S: 900,
            L: 18,
            y: 15,
            id: '9lMzlH',
          },
          {
            S: 936,
            L: 18,
            y: 13,
            id: '76MpT3',
          },
          {
            S: 972,
            L: 18,
            y: 15,
            id: 'uGOaqE',
          },
          {
            S: 1008,
            L: 18,
            y: 17,
            id: 'siNPfo',
          },
          {
            S: 1044,
            L: 18,
            y: 18,
            id: 'gAR_cq',
          },
          {
            S: 1080,
            L: 54,
            y: 20,
            id: 'EVvnwg',
          },
          {
            S: 630,
            L: 9,
            y: 17,
            id: '6vBJ9v',
          },
          {
            S: 639,
            L: 9,
            y: 15,
            id: 'P0Ublc',
          },
          {
            S: 774,
            L: 9,
            y: 17,
            id: 'BsTFFV',
          },
          {
            S: 783,
            L: 9,
            y: 15,
            id: 'JLZuK2',
          },
        ],
        barsNumber: 4,
        gridNumber: 8,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Clarinet',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
