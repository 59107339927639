import { SongType } from "../player/utils";

export const SamplerDrumsKinder: SongType = {
  id: 'g4ZfYlo4gy',
  beat: 4,
  tempo: 78,
  tonic: 'c',
  scale: 'major',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [
      {
        id: 'EiiniC',
        items: [
          {
            S: 0,
            L: 18,
            y: 10,
            id: 'Vy_yhT',
          },
          {
            S: 72,
            L: 18,
            y: 9,
            id: 'hDTmjp',
          },
          {
            S: 144,
            L: 18,
            y: 9,
            id: 'jkMGWy',
          },
          {
            S: 216,
            L: 18,
            y: 9,
            id: 'RBO0Xi',
          },
          {
            S: 180,
            L: 18,
            y: 0,
            id: 'hFINk6',
          },
          {
            S: 216,
            L: 18,
            y: 0,
            id: 'TjUkd2',
          },
          {
            S: 0,
            L: 18,
            y: 4,
            id: 'CP0x8I',
          },
          {
            S: 54,
            L: 18,
            y: 4,
            id: 'RE8g6x',
          },
          {
            S: 108,
            L: 18,
            y: 4,
            id: 'N3u1jF',
          },
          {
            S: 180,
            L: 18,
            y: 4,
            id: 'M5G5_d',
          },
          {
            S: 216,
            L: 18,
            y: 4,
            id: '-QS-Kl',
          },
        ],
        barsNumber: 1,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'drums',
      },
    ],
    melody: [],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'DrumsKinder',
    melody: 'Marimba',
    chord: 'Piano',
    bass: 'ViolinPlayingBass',
  },
};
