import { SongType } from "../player/utils";

export const SamplerUkulele: SongType = {
  id: 'cLnQbryTif',
  beat: 4,
  tempo: 90,
  tonic: 'c',
  scale: 'major',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: 'gr14wU',
        items: [
          {
            S: 0,
            L: 18,
            y: 11,
            id: 'pSh_EQ',
          },
          {
            S: 0,
            L: 18,
            y: 7,
            id: 'uD4n9p',
          },
          {
            S: 0,
            L: 18,
            y: 9,
            id: '9aCXW2',
          },
          {
            S: 36,
            L: 18,
            y: 11,
            id: '1_nA39',
          },
          {
            S: 36,
            L: 18,
            y: 7,
            id: '24YTgX',
          },
          {
            S: 36,
            L: 18,
            y: 9,
            id: 'MOzD6e',
          },
          {
            S: 54,
            L: 18,
            y: 7,
            id: 'anlhyI',
          },
          {
            S: 54,
            L: 18,
            y: 9,
            id: 'SPFISF',
          },
          {
            S: 54,
            L: 18,
            y: 11,
            id: 'yFbmo4',
          },
          {
            S: 180,
            L: 18,
            y: 6,
            id: 'CPxBID',
          },
          {
            S: 180,
            L: 18,
            y: 8,
            id: 'FkaSDx',
          },
          {
            S: 180,
            L: 18,
            y: 10,
            id: '9uvZSk',
          },
          {
            S: 198,
            L: 18,
            y: 6,
            id: 'k9UAID',
          },
          {
            S: 198,
            L: 18,
            y: 8,
            id: 't5V-RL',
          },
          {
            S: 198,
            L: 18,
            y: 10,
            id: 'a8LJi-',
          },
          {
            S: 324,
            L: 18,
            y: 7,
            id: '-tTwFs',
          },
          {
            S: 342,
            L: 18,
            y: 7,
            id: 'gaNvz6',
          },
          {
            S: 324,
            L: 18,
            y: 9,
            id: 'gC7CF5',
          },
          {
            S: 342,
            L: 18,
            y: 9,
            id: 'LmwB1_',
          },
          {
            S: 324,
            L: 18,
            y: 11,
            id: 'Dg4AK5',
          },
          {
            S: 342,
            L: 18,
            y: 11,
            id: 'dymPo8',
          },
          {
            S: 144,
            L: 18,
            y: 6,
            id: 'vurjKR',
          },
          {
            S: 144,
            L: 18,
            y: 8,
            id: '_kWawH',
          },
          {
            S: 144,
            L: 18,
            y: 10,
            id: '55jrkC',
          },
          {
            S: 288,
            L: 18,
            y: 7,
            id: 'T5vGA3',
          },
          {
            S: 288,
            L: 18,
            y: 9,
            id: 'YPYflZ',
          },
          {
            S: 288,
            L: 18,
            y: 11,
            id: 'va_lH-',
          },
          {
            S: 72,
            L: 18,
            y: 9,
            id: 'nnClVJ',
          },
          {
            S: 72,
            L: 18,
            y: 5,
            id: 'EMtKpJ',
          },
          {
            S: 72,
            L: 18,
            y: 7,
            id: '536iJQ',
          },
          {
            S: 108,
            L: 18,
            y: 9,
            id: 'HX2-0H',
          },
          {
            S: 108,
            L: 18,
            y: 5,
            id: 'xaOkg6',
          },
          {
            S: 108,
            L: 18,
            y: 7,
            id: 'c1J_P8',
          },
          {
            S: 126,
            L: 18,
            y: 5,
            id: 'N7ISt3',
          },
          {
            S: 126,
            L: 18,
            y: 7,
            id: 'CIj2ru',
          },
          {
            S: 126,
            L: 18,
            y: 9,
            id: 'JaA3ja',
          },
          {
            S: 396,
            L: 18,
            y: 5,
            id: 'fbc81p',
          },
          {
            S: 414,
            L: 18,
            y: 5,
            id: 'ewTx7H',
          },
          {
            S: 396,
            L: 18,
            y: 7,
            id: 'cnT8zU',
          },
          {
            S: 414,
            L: 18,
            y: 7,
            id: 'HbJEAQ',
          },
          {
            S: 396,
            L: 18,
            y: 9,
            id: 'dEhgYu',
          },
          {
            S: 414,
            L: 18,
            y: 9,
            id: 'LnZyZS',
          },
          {
            S: 360,
            L: 18,
            y: 5,
            id: '6vl2Mz',
          },
          {
            S: 360,
            L: 18,
            y: 7,
            id: '654L4h',
          },
          {
            S: 360,
            L: 18,
            y: 9,
            id: 'HK7UZn',
          },
          {
            S: 432,
            L: 18,
            y: 6,
            id: 'EknFQ8',
          },
          {
            S: 468,
            L: 18,
            y: 6,
            id: 'xSxOge',
          },
          {
            S: 486,
            L: 18,
            y: 6,
            id: 'ceaK_P',
          },
          {
            S: 432,
            L: 18,
            y: 8,
            id: '21xZMQ',
          },
          {
            S: 468,
            L: 18,
            y: 8,
            id: 'TApgdx',
          },
          {
            S: 486,
            L: 18,
            y: 8,
            id: 'GqUw3n',
          },
          {
            S: 432,
            L: 18,
            y: 10,
            id: 'Wyrv1U',
          },
          {
            S: 468,
            L: 18,
            y: 10,
            id: 'OTi44W',
          },
          {
            S: 486,
            L: 18,
            y: 10,
            id: 'Io6hRs',
          },
          {
            S: 504,
            L: 18,
            y: 3,
            id: 'kSJajt',
          },
          {
            S: 504,
            L: 18,
            y: 5,
            id: 'ZRxIj5',
          },
          {
            S: 504,
            L: 18,
            y: 7,
            id: 'm-1lW-',
          },
          {
            S: 216,
            L: 18,
            y: 5,
            id: '2y-WLI',
          },
          {
            S: 252,
            L: 18,
            y: 6,
            id: 'wRSlhp',
          },
          {
            S: 216,
            L: 18,
            y: 7,
            id: 'dhyhE0',
          },
          {
            S: 252,
            L: 18,
            y: 8,
            id: 'Xu7jJu',
          },
          {
            S: 216,
            L: 18,
            y: 9,
            id: 'zex0pn',
          },
          {
            S: 252,
            L: 18,
            y: 10,
            id: 'g3zD96',
          },
          {
            S: 540,
            L: 18,
            y: 10,
            id: 'DzDC2v',
          },
          {
            S: 540,
            L: 18,
            y: 12,
            id: 'ioG6AE',
          },
          {
            S: 540,
            L: 18,
            y: 14,
            id: 'ZH5hVO',
          },
        ],
        barsNumber: 2,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Ukulele',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
