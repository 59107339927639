
import * as Tone from 'tone';
import { chains, getLoopLength, midisToToneData, NoteType, PlayingDetial, ResourceName, samplers, SongType, TrackNames, TrackNamesType, updateResource, useTrackResources, ValueOfChains } from './utils';

export default function Player() {
  let part: Tone.Part;
  type NotePlayParams = ((params: NoteType & { resourceName: ResourceName }) => void)
  let handlerNotePlay: NotePlayParams | undefined

  const updateVolume = (trackName: TrackNamesType, value: number | 'muted') => {
    const currentPlayer = samplers[useTrackResources[trackName]];
    if (!currentPlayer) throw new Error('useTrackResources[trackName] not initialize');
    if (value === 'muted') {
      currentPlayer.volume.value = -Infinity;
    } else {
      currentPlayer.volume.value = value;
    }
  };

  const updateChains = (trackName: TrackNamesType, { type, value }: ValueOfChains) => {
    const chainsTarget = chains[trackName]
    if (type === 'panner' && chainsTarget.panner.pan.value !== value) {
      chainsTarget.panner.pan.value = value;
    }
    if (type === 'reverb' && chainsTarget.reverb.decay !== value) {
      chainsTarget.reverb.decay = value;
    }

    if (type === 'feedbackDelay') {
      if (chainsTarget.feedbackDelay.feedback.value !== value[0]) {
        [chainsTarget.feedbackDelay.feedback.value] = value;
      }

      if (value[0] === 0 && chainsTarget.feedbackDelay.delayTime.value !== 0) {
        chainsTarget.feedbackDelay.delayTime.value = 0;
      } else if (chainsTarget.feedbackDelay.delayTime.value !== value[1]) {
        [, chainsTarget.feedbackDelay.delayTime.value] = value;
      }
    }
  };

  const setBeat = (beat: number) => {
    Tone.Transport.timeSignature = [beat, 4];
  };

  const setBpm = (value: number) => {
    Tone.Transport.bpm.value = value;
  };

  const updateBySong = (song: SongType) => {
    TrackNames.forEach((trackName) => {
      updateResource(song.resources[trackName], trackName);
      updateVolume(
        trackName,
        song.volume[trackName].muted ? 'muted' : song.volume[trackName].value,
      );
      updateChains(trackName, { type: 'panner', value: song.volume[trackName].pan });
      updateChains(trackName, { type: 'reverb', value: song.volume[trackName].reverb });
      updateChains(trackName, {
        type: 'feedbackDelay',
        value: [
          song.volume[trackName].echo_loss,
          song.volume[trackName].echo_interval,
        ],
      });
      setBeat(song.beat);
      setBpm(song.tempo);
    });
  }

  const actions = {
    play: () => {
      Tone.Transport.stop();
      Tone.Transport.start(undefined, 0);
      part.start(undefined, 0);
    },
    stop: () => {
      if (!part) return;
      // Tone.Transport.stop();
      part.stop(0);
    },
  }

  const updateMidis = (detail: PlayingDetial | undefined) => {
    if (!detail) { actions.stop(); return; }
    if (part) part.dispose();

    const midiData: NoteType[] = midisToToneData(
      detail.jams,
      detail.config,
      detail.fromBeginning,
    );

    part = new Tone.Part<NoteType>((time, note) => {
      const player = samplers[useTrackResources[note.trackName]];
      if (!player || (player instanceof Tone.Sampler && !player.loaded)) return;
      if (note.trackName === 'drums') {
        player.triggerAttack(note.pitchName, time + 0.05);
      } else {
        player.triggerAttackRelease(note.pitchName, note.duration, time + 0.05);
      }
      if (player.volume.value !== -Infinity) {
        handlerNotePlay?.({
          ...note,
          resourceName: useTrackResources[note.trackName],
        })
      }
    }, midiData);

    const loopLength = getLoopLength(detail.jams);
    part.loop = detail.loop;
    part.loopEnd = `${loopLength}:0:0`;
  }

  window.addEventListener("pointerdown", () => {
    Tone.start();
    Tone.Transport.start();
  })

  return {
    instrumentChange: updateResource,
    updateBySong,
    play: actions.play,
    stop: actions.stop,
    updateMidis,
    setHandlerNotePlayCallback: (callback: NotePlayParams) => {
      handlerNotePlay = callback;
    }
  }
}
