import * as Tone from "tone";
import { Loader, LoaderResource } from "pixi.js-legacy";

export type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export const MidiMinCommonMultiple = 288;

export const roleNames = [
    "donut",
    "rappit",
    "maggie",
    "wako",
    "dali",
    "shojo",
    "marsh",
    "lulu",
    "fann",
    "chirpie",
    "fluffy",
    "karl",
    "pisces",
    "fuyu",
    "sandy",
    "echo_delay",
    "spike",
] as const;

export const DrumsType = [
    "clap",
    "conga",
    "kick",
    "floor_tom",
    "mid_tom",
    "high_tom",
    "snare",
    "open_hat",
    "closed_hat",
    "ride",
    "stick_hits",
    "crash",
    "claves",
    "hihat_foot",
    "kick_strong",
    "maracas",
    "semiopen_hat",
    "rim",
    "rattle",
    "rhythm_stick",
    "tinkle",
    "huanbao",
    "triangle_side",
    "triangle",
    "one_tone",
    "castanet",
    "fish",
    "two_tone_1",
    "two_tone_2",
    "snare_reverb",
] as const;

export const drumsNames = [
    "AcousticDrum",
    "808DrumMachine",
    "DrumsKinder",
    "Summer",
] as const;

export const bassNames = [
    "ElectricBass",
    "SquareBass",
    "ViolinPlayingBass",
    "PianoBass",
    "SawtoothBass",
] as const;

export const melodyAndChordNames = [
    "Piano",
    "Marimba",
    "Clarinet",
    "Square",
    "ViolinPlucking",
    "ViolinPlaying",
    "Guitar",
    "Ukulele",
    "Guzheng",
    "Melodica",
    "Recorder",
    "SuperSawtooth",
    "Birdie",
] as const;

export const notes = [
    "c",
    "c_sharp",
    "d",
    "d_sharp",
    "e",
    "f",
    "f_sharp",
    "g",
    "g_sharp",
    "a",
    "a_sharp",
    "b",
] as const;

export const scaleNames = [
    "major",
    "penta",
    "lydian",
    "mixolydian",
    "minor",
    "harmonic",
    "dorian",
    "phrygian",
    "blues",
    "japanese",
    "wholetone",
    "persian",
    "chromatic",
] as const;

export const ScaleDetail: Record<
    ScaleType,
    {
        details: number[];
    }
> = {
    major: { details: [0, 2, 4, 5, 7, 9, 11] },
    penta: { details: [0, 2, 4, 7, 9] },
    lydian: { details: [0, 2, 4, 6, 7, 9, 11] },
    mixolydian: { details: [0, 2, 4, 5, 7, 9, 10] },

    minor: { details: [0, 2, 3, 5, 7, 8, 10] },
    harmonic: { details: [0, 2, 3, 5, 7, 8, 11] },
    dorian: { details: [0, 2, 3, 5, 7, 9, 10] },
    phrygian: { details: [0, 1, 3, 5, 7, 8, 10] },
    blues: { details: [0, 3, 5, 6, 7, 10] },

    japanese: { details: [0, 2, 3, 7, 8] },
    wholetone: { details: [0, 2, 4, 6, 8, 10] },
    persian: { details: [0, 1, 4, 5, 6, 8, 11] },
    chromatic: {
        details: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    },
};

export const TrackNames = ["drums", "melody", "chord", "bass"] as const;
export const BeatChoices = [3, 4] as const;
export const GridChoices = [3, 4, 8] as const;
export const BarsNumberChoices = [1, 2, 4, 8] as const;
export const TimbreChoices = [
    "pan",
    "echo_loss",
    "echo_interval",
    "reverb",
] as const;

export type TimbreChoicesType = ArrayElement<typeof TimbreChoices>;
export type ScaleType = ArrayElement<typeof scaleNames>;
export type NotesType = ArrayElement<typeof notes>;
export type BeatNumber = ArrayElement<typeof BeatChoices>;
export type GridNumber = ArrayElement<typeof GridChoices>;
export type BarsNumber = ArrayElement<typeof BarsNumberChoices>;
export type RolesNameType = ArrayElement<typeof roleNames>;
export type Drums = ArrayElement<typeof DrumsType>;
export type DrumsNamesType = ArrayElement<typeof drumsNames>;
export type BassNamesType = ArrayElement<typeof bassNames>;
export type MelodyAndChordNamesType = ArrayElement<typeof melodyAndChordNames>;
export type TrackNamesType = ArrayElement<typeof TrackNames>;

export type ResourceName =
    | DrumsNamesType
    | BassNamesType
    | MelodyAndChordNamesType;

export type AnimalDetail = {
    id: number;
    price: number;
    resources: ResourceName[];
};

export type NoteType = {
    pitchName: string;
    time: string;
    duration: string;
    trackName: TrackNamesType;
};

export type DrumsAnimalConfig = {
    isDrums: true;
    idle: number;
    idleZIndexTop: boolean;
    actions: { frames: number; drums: Drums[] }[];
    tap: {
        frames: number;
        audios: number;
    };
};

export type NotDrumsAnimalConfig = {
    isDrums: false;
    idle: number;
    actions: number[];
    resources?: Partial<Record<ResourceName, [number, number]>>;
    tap: {
        frames: number;
        audios: number;
    };
};

export type AnimalConfig = Record<
    RolesNameType,
    DrumsAnimalConfig | NotDrumsAnimalConfig
>;

export const animalConfigs: AnimalConfig = {
    rappit: {
        idle: 6,
        isDrums: true,
        idleZIndexTop: true,
        actions: [
            {
                frames: 11,
                drums: [
                    "clap",
                    "conga",
                    "kick",
                    "floor_tom",
                    "mid_tom",
                    "high_tom",
                    "snare",
                    "stick_hits",
                    "crash",
                    "claves",
                    "kick_strong",
                    "maracas",
                    "rim",
                ],
            },
            {
                frames: 11,
                drums: ["open_hat", "closed_hat", "ride", "semiopen_hat", "hihat_foot"],
            },
        ],
        tap: {
            frames: 7,
            audios: 2,
        },
    },
    marsh: {
        idle: 6,
        isDrums: true,
        idleZIndexTop: false,
        actions: [
            {
                frames: 11,
                drums: ["kick", "kick_strong"],
            },
            {
                frames: 7,
                drums: ["maracas"],
            },
            {
                frames: 9,
                drums: ["snare", "rim"],
            },
            {
                frames: 9,
                drums: ["mid_tom", "high_tom"],
            },
            {
                frames: 9,
                drums: ["conga", "floor_tom"],
            },
            {
                frames: 9,
                drums: ["crash", "ride"],
            },
            {
                frames: 8,
                drums: ["clap", "stick_hits", "claves"],
            },
            {
                frames: 10,
                drums: ["open_hat", "closed_hat", "semiopen_hat", "hihat_foot"],
            },
        ],
        tap: {
            frames: 9,
            audios: 3,
        },
    },
    sandy: {
        idle: 11,
        isDrums: true,
        idleZIndexTop: false,
        actions: [
            {
                frames: 11,
                drums: ["conga", "floor_tom", "mid_tom", "high_tom"],
            },
            {
                frames: 11,
                drums: ["snare", "clap", "rim", "claves"],
            },
            {
                frames: 11,
                drums: ["kick"],
            },
            {
                frames: 11,
                drums: ["open_hat", "closed_hat", "maracas"],
            },
        ],
        tap: {
            frames: 11,
            audios: 1,
        },
    },
    echo_delay: {
        idle: 11,
        isDrums: true,
        idleZIndexTop: false,
        actions: [
            {
                frames: 12,
                drums: ["triangle_side", "triangle"],
            },
            {
                frames: 11,
                drums: ["fish", "rhythm_stick"],
            },
            {
                frames: 8,
                drums: ["castanet", "maracas"],
            },
            {
                frames: 10,
                drums: ["tinkle", "huanbao", "rattle"],
            },
            {
                frames: 10,
                drums: ["two_tone_1", "two_tone_2", "one_tone"],
            },
        ],
        tap: {
            frames: 11,
            audios: 1,
        },
    },
    maggie: {
        isDrums: false,
        idle: 11,
        actions: [11, 11],
        tap: {
            frames: 8,
            audios: 2,
        },
    },
    dali: {
        isDrums: false,
        idle: 7,
        actions: [11, 11, 9],
        resources: {
            ViolinPlaying: [0, 2],
            ViolinPlucking: [2, 3],
        },
        tap: {
            frames: 8,
            audios: 2,
        },
    },
    shojo: {
        isDrums: false,
        idle: 9,
        actions: [11],
        tap: {
            frames: 9,
            audios: 2,
        },
    },
    fann: {
        isDrums: false,
        idle: 13,
        actions: [12],
        tap: {
            frames: 11,
            audios: 1,
        },
    },
    lulu: {
        isDrums: false,
        idle: 10,
        actions: [11],
        tap: {
            frames: 10,
            audios: 3,
        },
    },
    donut: {
        isDrums: false,
        idle: 9,
        actions: [9],
        tap: {
            frames: 9,
            audios: 3,
        },
    },
    spike: {
        isDrums: false,
        idle: 11,
        actions: [11],
        tap: {
            frames: 11,
            audios: 3,
        },
    },
    fuyu: {
        isDrums: false,
        idle: 9,
        actions: [11, 11],
        tap: {
            frames: 10,
            audios: 1,
        },
    },
    fluffy: {
        isDrums: false,
        idle: 11,
        actions: [11, 11],
        tap: {
            frames: 11,
            audios: 2,
        },
    },
    chirpie: {
        isDrums: false,
        idle: 11,
        actions: [10, 10],
        tap: {
            frames: 10,
            audios: 2,
        },
    },
    wako: {
        isDrums: false,
        idle: 11,
        actions: [11],
        tap: {
            frames: 11,
            audios: 1,
        },
    },
    pisces: {
        isDrums: false,
        idle: 14,
        actions: [11, 11],
        tap: {
            frames: 11,
            audios: 2,
        },
    },
    karl: {
        isDrums: false,
        idle: 11,
        actions: [11],
        tap: {
            frames: 11,
            audios: 1,
        },
    },
};

export const animalsDetails: Record<RolesNameType, AnimalDetail> = {
    donut: {
        id: 1000,
        price: 0,
        resources: ["Guitar", "Ukulele"],
    },
    rappit: {
        id: 1001,
        price: 0,
        resources: ["AcousticDrum"],
    },
    maggie: {
        id: 1002,
        price: 0,
        resources: ["PianoBass", "Piano"],
    },
    wako: {
        id: 1003,
        price: 0,
        resources: ["ElectricBass"],
    },
    dali: {
        id: 1004,
        price: 899,
        resources: ["ViolinPlucking", "ViolinPlaying"],
    },
    shojo: {
        id: 1011,
        price: 1499,
        resources: ["Guzheng"],
    },
    marsh: {
        id: 1012,
        price: 1499,
        resources: ["Summer"],
    },
    lulu: {
        id: 1013,
        price: 1499,
        resources: ["Clarinet"],
    },
    fann: {
        id: 1006,
        price: 899,
        resources: ["Square", "SquareBass"],
    },
    chirpie: {
        id: 1005,
        price: 899,
        resources: ["Birdie"],
    },
    fluffy: {
        id: 1007,
        price: 899,
        resources: ["Recorder"],
    },
    karl: {
        id: 1008,
        price: 899,
        resources: ["Melodica"],
    },
    pisces: {
        id: 1014,
        price: 3099,
        resources: ["ViolinPlayingBass"],
    },
    fuyu: {
        id: 1009,
        price: 899,
        resources: ["Marimba"],
    },
    sandy: {
        id: 1015,
        price: 3099,
        resources: ["808DrumMachine"],
    },
    echo_delay: {
        id: 1016,
        price: 3099,
        resources: ["DrumsKinder"],
    },
    spike: {
        id: 1010,
        price: 899,
        resources: ["SawtoothBass", "SuperSawtooth"],
    },
};

export const DrumsIcons: Record<DrumsNamesType, Drums[]> = {
    AcousticDrum: [
        "ride",
        "crash",
        "rim",
        "floor_tom",
        "high_tom",
        "open_hat",
        "semiopen_hat",
        "closed_hat",
        "hihat_foot",
        "snare",
        "kick_strong",
        "kick",
    ],
    "808DrumMachine": [
        "conga",
        "claves",
        "maracas",
        "rim",
        "floor_tom",
        "mid_tom",
        "high_tom",
        "open_hat",
        "closed_hat",
        "clap",
        "snare",
        "kick",
    ],
    DrumsKinder: [
        "rattle",
        "rhythm_stick",
        "tinkle",
        "huanbao",
        "triangle_side",
        "triangle",
        "one_tone",
        "castanet",
        "maracas",
        "fish",
        "two_tone_1",
        "two_tone_2",
    ],
    Summer: [
        "ride",
        "rim",
        "maracas",
        "floor_tom",
        "high_tom",
        "open_hat",
        "closed_hat",
        "hihat_foot",
        "snare_reverb",
        "snare",
        "kick_strong",
        "kick",
    ],
};

const polvyBaseUrl = "https://mpv.videocc.net/f376941452";

export const musicResources: Record<ResourceName, ResourceToneDetailType> = {
    // drums
    AcousticDrum: {
        type: "sampler",
        track: ["drums"],
        baseUrl: polvyBaseUrl,
        urls: {
            C0: "/9/f3769414524e5a217f7ede7161b959c9_1.mp3",
            C1: "/6/f3769414523b99d429ae17d98d6f5726_1.mp3",
            C2: "/9/f376941452539c86395f807bee595059_1.mp3",
            C3: "/b/f376941452990d482aa32480355e585b_1.mp3",
            C4: "/b/f3769414529b2e2d1fc19c569da10a8b_1.mp3",
            C5: "/b/f376941452c0355e2cd28c78848cc15b_1.mp3",
            C6: "/a/f37694145239c999da341f4b3c7d530a_1.mp3",
            C7: "/7/f376941452f1d786a11c5f03291f2f27_1.mp3",
            C8: "/2/f37694145229a813c8bda436777739f2_1.mp3",
            C9: "/0/f37694145209fcb09f825e1a7df04930_1.mp3",
            C10: "/8/f376941452d4156a33185d7a73d32298_1.mp3",
            C11: "/1/f376941452cd7bf86cb15ca352892e41_1.mp3",
        },
    },
    "808DrumMachine": {
        type: "sampler",
        track: ["drums"],
        baseUrl: polvyBaseUrl,
        urls: {
            C0: "/a/f3769414522cf4cc4f4721b14347bcea_1.mp3",
            C1: "/0/f376941452608550f6789e0794eb4820_1.mp3",
            C2: "/4/f376941452217b917116f5c89354d484_1.mp3",
            C3: "/a/f376941452745b5ace39c8fc45e250ba_1.mp3",
            C4: "/e/f3769414525d9ed97e36c77e28664bae_1.mp3",
            C5: "/9/f3769414523e18defc04dc5fc5a98489_1.mp3",
            C6: "/9/f376941452ae5c3b1bd45b7b70237179_1.mp3",
            C7: "/0/f37694145249912c3f3d547a568f87c0_1.mp3",
            C8: "/d/f376941452cc56aca0db40a26ad7df4d_1.mp3",
            C9: "/d/f376941452cdf1987df035d42c09a3cd_1.mp3",
            C10: "/4/f3769414521779ddba2c0f809d2d4964_1.mp3",
            C11: "/e/f376941452f09147c951e885ab67e2ae_1.mp3",
        },
    },
    // 幼儿园打击乐
    DrumsKinder: {
        type: "sampler",
        baseUrl: polvyBaseUrl,
        track: ["drums"],
        urls: {
            C0: "/e/f376941452d7d665794dc8bca33f9b6e_1.mp3",
            C1: "/f/f376941452f63fa3a7173b89cfdf54cf_1.mp3",
            C2: "/2/f3769414525d99821f9e91f7fddd8892_1.mp3",
            C3: "/0/f3769414524a093e57bd5647e24fed60_1.mp3",
            C4: "/0/f376941452e42503743397cb4ee67ce0_1.mp3",
            C5: "/2/f376941452fa6fa730674d7e5e560a92_1.mp3",
            C6: "/4/f376941452af8a4c954de1eb08ac9354_1.mp3",
            C7: "/d/f376941452f92603ace1651530b571ad_1.mp3",
            C8: "/0/f376941452ab47ad3c3d246027b2f1e0_1.mp3",
            C9: "/c/f376941452dfb7cf900c33834a763a0c_1.mp3",
            C10: "/d/f37694145220e06ecd9ea3be0aee28ed_1.mp3",
            C11: "/2/f3769414522f5478fcbe208911dbe4c2_1.mp3",
        },
    },
    // 夏日鼓组
    Summer: {
        type: "sampler",
        track: ["drums"],
        baseUrl: polvyBaseUrl,
        urls: {
            C0: "/3/f3769414520516f65f3ce911a6d265d3_1.mp3",
            C1: "/a/f376941452c7bc4a8f59431d97af205a_1.mp3",
            C2: "/0/f3769414523534546ddc883e3365f620_1.mp3",
            C3: "/8/f3769414521db59d7101927f62d74548_1.mp3",
            C4: "/2/f376941452866f61ef64fa6f76457a42_1.mp3",
            C5: "/b/f376941452efb454f2d593c77d52eb4b_1.mp3",
            C6: "/2/f37694145262b1a7900e3104f67addd2_1.mp3",
            C7: "/4/f376941452c7eab749543c32b20cf284_1.mp3",
            C8: "/4/f3769414527e359072f717c97a434334_1.mp3",
            C9: "/3/f37694145285a1d79a4b004e47d1d153_1.mp3",
            C10: "/3/f37694145247d1548c5b082f381db353_1.mp3",
            C11: "/1/f3769414529ea4d565355cdbf372dc01_1.mp3",
        },
    },

    // melody and chords
    // 钢琴
    Piano: {
        type: "sampler",
        track: ["melody", "chord"],
        baseUrl: polvyBaseUrl,
        urls: {
            A2: "/7/f376941452cf4ee936d054cf7c4a0f17_1.mp3",
            A3: "/e/f376941452c9a4889496c7e6ecb3419e_1.mp3",
            A4: "/5/f3769414522f4a9eee0e257d7ef053e5_1.mp3",
            C2: "/8/f3769414522ec708bee31e408b3969e8_1.mp3",
            C3: "/2/f3769414529c570906708f1dbf0979b2_1.mp3",
            C4: "/d/f3769414521af2255c6c11d5000d4c4d_1.mp3",
            "D#2": "/b/f37694145277ab14a6f25cd50e07be0b_1.mp3",
            "D#3": "/0/f376941452c427e95fef81996ad8c350_1.mp3",
            "D#4": "/4/f376941452f3a8a7006d631b60738a94_1.mp3",
            "F#2": "/c/f376941452d933c85986ab54b340ffdc_1.mp3",
            "F#3": "/e/f3769414524e6e0bac230f129855d95e_1.mp3",
            "F#4": "/3/f37694145210ce0661cbcb1ee4fa4323_1.mp3",
        },
    },
    // 马林巴琴
    Marimba: {
        type: "sampler",
        track: ["melody", "chord"],
        baseUrl: polvyBaseUrl,
        urls: {
            A2: "/b/f376941452e9aecb7ae296d4dde1c8ab_1.mp3",
            A3: "/6/f376941452bb5ac41cb2390d50fcefa6_1.mp3",
            A4: "/1/f3769414523265a1496e6fe5c16e1171_1.mp3",
            C2: "/0/f3769414527991f1b1c670f30092f480_1.mp3",
            C3: "/6/f376941452090c17d7836365c2860026_1.mp3",
            C4: "/0/f376941452f64dd732416de87bdecee0_1.mp3",
            "D#2": "/1/f3769414521d88fea3e7a6880f0c3e71_1.mp3",
            "D#3": "/0/f376941452c305e3a0fbcf06b0de7480_1.mp3",
            "D#4": "/5/f3769414525094979249248171377e85_1.mp3",
            "F#2": "/f/f376941452a281b995ea0d2bdba79ccf_1.mp3",
            "F#3": "/8/f376941452018f7c280cb44daa42ac78_1.mp3",
            "F#4": "/a/f37694145209af24b1b9178b5e7d2c4a_1.mp3",
        },
    },
    // 单簧管
    Clarinet: {
        type: "sampler",
        track: ["melody", "chord"],
        baseUrl: polvyBaseUrl,
        urls: {
            A2: "/9/f376941452f69b0cf3dfa496f1b299a9_1.mp3",
            A3: "/3/f376941452aaaf140b3120427d173c33_1.mp3",
            A4: "/3/f376941452b5bc64c086831807d3b143_1.mp3",
            C3: "/7/f376941452ebd5fe0a20e7b229fcec07_1.mp3",
            C4: "/d/f3769414523a299a33ba836ed3aaf62d_1.mp3",
            "D#2": "/d/f3769414522ddea8b6f2a1f10dca84ed_1.mp3",
            "D#3": "/d/f376941452357264ce60026075ff72dd_1.mp3",
            "D#4": "/1/f3769414527572594b7119bc3ce53231_1.mp3",
            "F#2": "/d/f37694145210ce2e0c1c7bee4e1aafad_1.mp3",
            "F#3": "/b/f376941452a414bd8e3cbf037e5471db_1.mp3",
            "F#4": "/2/f37694145272a0f74d3aacff3eaa6b82_1.mp3",
        },
    },
    // 方波
    Square: {
        type: "synth",
        track: ["melody", "chord"],
        newOne: () =>
            new Tone.PolySynth(Tone.AMSynth, {
                harmonicity: 3.999,
                oscillator: {
                    type: "square",
                },
                envelope: {
                    attack: 0.03,
                    decay: 0.3,
                    sustain: 0.7,
                    release: 0.8,
                },
                modulation: {
                    volume: 12,
                    type: "square6",
                },
                modulationEnvelope: {
                    attack: 2,
                    decay: 3,
                    sustain: 0.8,
                    release: 0.1,
                },
            }).toDestination(),
    },
    // 小提琴拨弦
    ViolinPlucking: {
        type: "sampler",
        track: ["melody", "chord"],
        baseUrl: polvyBaseUrl,
        urls: {
            A2: "/2/f376941452daae53133046b012348fa2_1.mp3",
            A3: "/d/f3769414524a3d35dba7c3a919a6bfed_1.mp3",
            A4: "/3/f37694145255471d01cfae85acd0acd3_1.mp3",
            B2: "/8/f376941452cffa88d45f12a555412cd8_1.mp3",
            B3: "/7/f3769414528927a288bd03ded4605277_1.mp3",
            B4: "/0/f376941452221f37350f629714679910_1.mp3",
            C3: "/8/f376941452354beffa9229898ff73c98_1.mp3",
            C4: "/0/f376941452c1744cc30137d49f232f80_1.mp3",
            D3: "/c/f37694145238cf7ccf52318e27ffcbac_1.mp3",
            D4: "/c/f376941452654590ecc06ab5678fac6c_1.mp3",
            E3: "/c/f376941452cfac7a13c03f813add274c_1.mp3",
            E4: "/3/f376941452c928dfa56a09e8fa533da3_1.mp3",
            F3: "/d/f3769414520fa794c9b9e4814af1fedd_1.mp3",
            F4: "/c/f3769414521e18305dfb7969c61df12c_1.mp3",
            G2: "/8/f37694145236450c1c6a89ec0ec4dfa8_1.mp3",
            G3: "/f/f3769414524494d67226115e6a9eab9f_1.mp3",
            G4: "/d/f3769414523820bbd4412266e09f8d6d_1.mp3",
        },
    },
    // 小提琴拉弓
    ViolinPlaying: {
        type: "sampler",
        track: ["melody", "chord"],
        baseUrl: polvyBaseUrl,
        urls: {
            A2: "/e/f376941452edb215309db4fcc13d67be_1.mp3",
            A3: "/5/f376941452e1323aa85cdd6fc2c1a3c5_1.mp3",
            A4: "/1/f376941452d924057d7becd1209c83a1_1.mp3",
            C3: "/4/f3769414527b84763fe073d52c2e6f94_1.mp3",
            C4: "/d/f376941452928abed6eece806a7d8aed_1.mp3",
            D3: "/3/f376941452b86a3c1e317012d60bb5c3_1.mp3",
            D4: "/5/f376941452e432eeeb35acce206d5af5_1.mp3",
            F3: "/3/f37694145270bc0b9064c51c52a8b3c3_1.mp3",
            F4: "/4/f37694145284b2efc1c2eab87fec1804_1.mp3",
            G2: "/c/f376941452106f00671a7132eae38dfc_1.mp3",
            G3: "/7/f376941452fab61fe55bd7c4d059d777_1.mp3",
            G4: "/7/f37694145211818b1dcc6342bb66f367_1.mp3",
        },
    },
    // 吉他
    Guitar: {
        type: "sampler",
        track: ["melody", "chord"],
        baseUrl: polvyBaseUrl,
        urls: {
            "A#2": "/d/f3769414522ca909abd1a7878995c4ad_1.mp3",
            "A#3": "/3/f376941452a5d4463fa8841ac3e93093_1.mp3",
            "A#4": "/e/f3769414521ba39d293cd563be03f57e_1.mp3",
            C2: "/d/f376941452ef0632d413600044a8b2ad_1.mp3",
            C3: "/7/f376941452b2e0ea7d86a007052a0aa7_1.mp3",
            C4: "/6/f376941452c9affa7ee17e262b636ab6_1.mp3",
            D2: "/e/f376941452ff302d2dd826c937b86d0e_1.mp3",
            D3: "/f/f376941452755d554ed951bca2a4ad4f_1.mp3",
            D4: "/4/f3769414520a06985a85a82ebe9d4154_1.mp3",
            E2: "/0/f3769414520caf161855db5b2adce5b0_1.mp3",
            E3: "/8/f376941452ee9c5a891cda9498534788_1.mp3",
            E4: "/3/f3769414524d94b1d1b2fa56a9f4a023_1.mp3",
            "F#2": "/f/f3769414522081fe9346cc4451c4ddff_1.mp3",
            "F#3": "/4/f37694145220e61752d56cfb38e064a4_1.mp3",
            "F#4": "/a/f3769414526e9ad0d64d79b7acc4b53a_1.mp3",
            "G#2": "/3/f376941452f707bcee90c09d1b1b7703_1.mp3",
            "G#3": "/4/f37694145206ce688fe293a19dc0a694_1.mp3",
            "G#4": "/5/f3769414525ff3110735078df9043265_1.mp3",
        },
    },
    // 乌克丽丽
    Ukulele: {
        type: "sampler",
        track: ["melody", "chord"],
        baseUrl: polvyBaseUrl,
        urls: {
            A3: "/f/f376941452aa595d0ba9efc4626b11df_1.mp3",
            A4: "/7/f376941452b965e4ae4967722829ad77_1.mp3",
            C3: "/4/f376941452e6bce324301230b935f184_1.mp3",
            C4: "/6/f376941452e8ef53807f733fef925aa6_1.mp3",
            D3: "/f/f37694145242d562899c26b34b72e4cf_1.mp3",
            D4: "/3/f376941452ca72a1cc6b7b484fa75c63_1.mp3",
            E3: "/1/f376941452714ef4151fd89fd829ae51_1.mp3",
            E4: "/e/f376941452beaa77fd4d1644bd57494e_1.mp3",
            G3: "/b/f3769414523ad8e1b7571b14ee26860b_1.mp3",
            G4: "/6/f376941452d744c4fb27e9139da8e6b6_1.mp3",
        },
    },
    // 古筝
    Guzheng: {
        type: "sampler",
        track: ["melody", "chord"],
        baseUrl: polvyBaseUrl,
        urls: {
            A2: "/4/f376941452bcc6a49cd952458bdd18d4_1.mp3",
            A3: "/1/f37694145276d331dfd7bd0c4a58ec11_1.mp3",
            A4: "/0/f37694145231ad53b95ecb7cb5612640_1.mp3",
            C2: "/0/f376941452eb692754eb53160c46a440_1.mp3",
            C3: "/9/f376941452eb5ac56aa9edfd74a3d969_1.mp3",
            C4: "/1/f376941452ff7cde60d0087d69ffab61_1.mp3",
            D2: "/d/f3769414520abf349f06aaa49cb5326d_1.mp3",
            D3: "/a/f376941452b26ff79c8016c235a8054a_1.mp3",
            D4: "/7/f3769414527d7f94cd746bf110ee0137_1.mp3",
            E2: "/b/f3769414527f331b5badf3f1bb81b8eb_1.mp3",
            E3: "/7/f37694145272948117f9e7a4a02f9657_1.mp3",
            E4: "/e/f376941452f5be022cb19f26e6fa991e_1.mp3",
            G2: "/0/f3769414525d8dbfbef36b912bb54bc0_1.mp3",
            G3: "/c/f3769414523c55cd1e34ec930adc156c_1.mp3",
            G4: "/4/f3769414527ab3e8918e492d35d9ba84_1.mp3",
        },
    },
    // 竖笛
    Recorder: {
        type: "sampler",
        track: ["melody", "chord"],
        baseUrl: polvyBaseUrl,
        urls: {
            A4: "/4/f37694145204ede972384a674fe2ff14_1.mp3",
            C4: "/b/f3769414520e468b116cfb7b83902a3b_1.mp3",
            D4: "/9/f3769414521d8d649b3be9ed2b405c39_1.mp3",
            E4: "/3/f376941452f5e7f30c812f22110b2cb3_1.mp3",
            G4: "/1/f376941452710d73277c549b62738651_1.mp3",
        },
    },
    // 口风琴
    Melodica: {
        type: "sampler",
        track: ["melody", "chord"],
        baseUrl: polvyBaseUrl,
        urls: {
            A2: "/f/f3769414523e387ea70aebc4b8ecdcef_1.mp3",
            A3: "/b/f3769414526aaae3959fbc339606746b_1.mp3",
            A4: "/4/f376941452d8221c5721cdaa15145874_1.mp3",
            C3: "/9/f376941452a3e8c5d047ba04fb991589_1.mp3",
            C4: "/b/f37694145253e543e616fea38bc7f23b_1.mp3",
            D3: "/2/f376941452153f7b3fb42e8661231532_1.mp3",
            D4: "/7/f376941452ca97e5c94571d80884cc07_1.mp3",
            E3: "/8/f3769414520e9ae628aa3a36ec552b48_1.mp3",
            E4: "/4/f376941452ffc77c5792ea6c0801a2e4_1.mp3",
            "F#2": "/7/f37694145218af89292f0b841df4a497_1.mp3",
            G3: "/2/f3769414528a6dc42766b8a882e91fe2_1.mp3",
            G4: "/1/f376941452be2bf1d1cab2ba2ef5e9e1_1.mp3",
        },
    },
    // 超级锯齿
    SuperSawtooth: {
        type: "synth",
        track: ["melody", "chord"],
        newOne: () =>
            new Tone.PolySynth(Tone.AMSynth, {
                oscillator: {
                    type: "fatsawtooth",
                    count: 3,
                    spread: 30,
                },
                envelope: {
                    attack: 0.01,
                    decay: 0.1,
                    sustain: 0.5,
                    release: 0.4,
                    attackCurve: "exponential",
                },
            }).toDestination(),
    },
    Birdie: {
        type: "synth",
        track: ["melody", "chord"],
        newOne: () => {
            const synth = new Tone.PolySynth(Tone.MonoSynth, {
                volume: 0,
                oscillator: {
                    type: "custom",
                    partials: [0, 0, 0, 1],
                },
                envelope: {
                    attack: 0.2,
                    release: 0.4,
                },
                filterEnvelope: {
                    attack: 0.01,
                    decay: 0.1,
                    sustain: 0.8,
                    release: 1.5,
                    baseFrequency: 50,
                    octaves: 4,
                },
            }).toDestination();

            const vibrato = new Tone.Vibrato(8, 0.04).toDestination();
            synth.chain(vibrato);

            return synth;
        },
    },
    // bass
    // 电贝斯
    ElectricBass: {
        type: "sampler",
        track: ["bass"],
        baseUrl: polvyBaseUrl,
        urls: {
            A0: "/a/f37694145218207485bd218cc8bfe55a_1.mp3",
            A1: "/9/f376941452a104421322bf918e86b8d9_1.mp3",
            A2: "/d/f376941452514a059887db9c4d751e6d_1.mp3",
            C0: "/2/f3769414520a90dc54c975bef6399c02_1.mp3",
            C1: "/e/f376941452dc4d7a5b02c8251c3b40ce_1.mp3",
            C2: "/e/f3769414522e5bdd6a1409178c0174ce_1.mp3",
            "D#0": "/e/f37694145265d3fe1f91f01839244d9e_1.mp3",
            "D#1": "/e/f376941452670b540f5af97115f324fe_1.mp3",
            "D#2": "/b/f376941452acfc2d8cc122d6f0d395eb_1.mp3",
            "F#0": "/a/f37694145290eae4d7c97f43323d277a_1.mp3",
            "F#1": "/7/f3769414525b6671a17be01b29865107_1.mp3",
            "F#2": "/e/f376941452a219013a6a07afaaf3560e_1.mp3",
        },
    },
    // 方波贝斯
    SquareBass: {
        type: "synth",
        track: ["bass"],
        newOne: () => {
            const synth = new Tone.PolySynth(Tone.MonoSynth, {
                oscillator: {
                    type: "square",
                },
                filter: {
                    Q: 4.5,
                    type: "lowpass",
                    rolloff: -24,
                },
                envelope: {
                    attack: 0.03,
                    decay: 0.3,
                    sustain: 0.7,
                    release: 0.5,
                },
                filterEnvelope: {
                    attack: 0.03,
                    decay: 0.1,
                    sustain: 0.8,
                    release: 1.5,
                    baseFrequency: 44,
                    octaves: 4.4,
                },
            }).toDestination();

            return synth;
        },
    },
    // 拉弓
    ViolinPlayingBass: {
        type: "sampler",
        track: ["bass"],
        baseUrl: polvyBaseUrl,
        urls: {
            "A#0": "/3/f376941452341f88230b1f6d00ece193_1.mp3",
            A1: "/3/f376941452ab834b2c8a6c453d4ba763_1.mp3",
            B2: "/6/f3769414526da5ddfa5b4d42da0ec0b6_1.mp3",
            "C#2": "/f/f376941452f39be8d5341dced6aa76bf_1.mp3",
            C1: "/9/f37694145208a6662bf373a0fae28409_1.mp3",
            D1: "/7/f37694145240bc8c5e38718798a5f9b7_1.mp3",
            E1: "/3/f376941452db5e99417978e4c0d7af03_1.mp3",
            E2: "/5/f376941452b55ea5de6e21996c3b6d15_1.mp3",
            "F#0": "/7/f37694145299a8de84f9babf36118417_1.mp3",
            "F#1": "/8/f376941452fecfbc57de2ef604ca0278_1.mp3",
            "G#1": "/e/f376941452f557f1579ff8a01162a59e_1.mp3",
            "G#2": "/b/f37694145209c4ae8a260c1a752b245b_1.mp3",
        },
    },
    // 低音钢琴
    PianoBass: {
        type: "sampler",
        track: ["bass"],
        baseUrl: polvyBaseUrl,
        urls: {
            A0: "/e/f3769414523014943bb855adca6e5c3e_1.mp3",
            A1: "/6/f3769414526812c279b0759783125606_1.mp3",
            A2: "/7/f376941452cf4ee936d054cf7c4a0f17_1.mp3",
            C0: "/a/f376941452e2e0ed4fb16074832bf50a_1.mp3",
            C1: "/b/f376941452aac830a00efcc465056dcb_1.mp3",
            C2: "/8/f3769414522ec708bee31e408b3969e8_1.mp3",
            "D#0": "/b/f376941452e88bcd2ee6707dc14b585b_1.mp3",
            "D#1": "/6/f376941452947918cbb8361c5129d476_1.mp3",
            "D#2": "/b/f37694145277ab14a6f25cd50e07be0b_1.mp3",
            "F#0": "/d/f376941452f53bd860784c567c56eeed_1.mp3",
            "F#1": "/1/f3769414529f9b01ad356892702f3761_1.mp3",
            "F#2": "/c/f376941452d933c85986ab54b340ffdc_1.mp3",
        },
    },
    // 锯齿低音
    SawtoothBass: {
        type: "synth",
        track: ["bass"],
        newOne: () =>
            new Tone.PolySynth(Tone.MonoSynth, {
                oscillator: {
                    type: "fatsawtooth",
                    count: 3,
                    spread: 30,
                },
                filter: {
                    Q: 4,
                    rolloff: -24,
                },
                envelope: {
                    attack: 0.03,
                    decay: 0.3,
                    sustain: 0.7,
                    release: 1.5,
                },
                filterEnvelope: {
                    attack: 0.05,
                    decay: 0.1,
                    sustain: 0.5,
                    release: 1.5,
                    baseFrequency: 40,
                    octaves: 4.4,
                    exponent: 2,
                },
            }).toDestination(),
    },
};

export type MidiItemType = {
    id: string;
    S: number;
    L: number;
    y: number;
};

export type MidiJamType = {
    id: string;
    items: MidiItemType[];
    barsNumber: BarsNumber;
    gridNumber: GridNumber;
    barIndex: number;
    trackName: TrackNamesType;
};

export type SongType = {
    beat: BeatNumber;
    tempo: number;
    tonic: NotesType;
    scale: ScaleType;
    id: string;
    jams: Record<TrackNamesType, MidiJamType[]>;
    tracks: Record<TrackNamesType, MidiJamType[]>;
    volume: Record<
        TrackNamesType,
        { value: number; muted: boolean } & Record<TimbreChoicesType, number>
    >;
    resources: SongUseResourcesType;
};

export function resourceLoader(
    resouces: { name: string; url: string }[],
    onProgress?: (loader: Loader) => void
) {
    const loader = new Loader();

    return new Promise<Record<string, LoaderResource>>((resolve, reject) => {
        loader.add(resouces).load((_, res) => {
            resolve(res);
        });
        loader.onError.add(reject);
        if (onProgress) loader.onProgress.add(onProgress);
    });
}

export const throttle = <T>(x: (params: T) => void, time = 1000 / 24) => {
    let inThrottle: boolean;

    return (args: T) => {
        if (inThrottle) return;
        inThrottle = true;
        x(args);
        const timeout = setTimeout(() => {
            inThrottle = false;
            clearTimeout(timeout);
        }, time);
    };
};

interface SamplesMap {
    [note: string]: Tone.ToneAudioBuffer | AudioBuffer | string;
    [midi: number]: Tone.ToneAudioBuffer | AudioBuffer | string;
}

type ResourceToneDetailType =
    | {
        type: "synth";
        track: TrackNamesType[];
        newOne: () => Tone.PolySynth;
    }
    | {
        type: "sampler";
        track: TrackNamesType[];
        urls: SamplesMap;
        baseUrl: string;
    };

export type SongUseResourcesType = {
    drums: DrumsNamesType;
    melody: MelodyAndChordNamesType;
    chord: MelodyAndChordNamesType;
    bass: BassNamesType;
};

export const getTone = (
    config: ResourceToneDetailType,
    onload?: () => void
) => {
    if (config.type === "sampler") {
        return new Tone.Sampler({
            urls: config.urls,
            release: 1,
            baseUrl: config.baseUrl,
            onload,
        }).toDestination();
    }
    onload?.();
    return config.newOne();
};

export const getPitchNameByIndex = (
    y: number,
    trackName: TrackNamesType,
    scaleLength: number,
    useNotes: NotesType[],
    tonicIndex: number
) => {
    let pitchName: string;
    const isDrums = trackName === "drums";
    const pitchBase = trackName === "bass" ? 0 : 2;

    if (isDrums) {
        pitchName = `C${y}`;
    } else {
        const useIndex = (scaleLength - (y % scaleLength)) % scaleLength;
        const name = useNotes[useIndex];
        const pitchOffset =
            Math.floor((scaleLength * 3 - y) / scaleLength) +
            (notes.indexOf(name) < tonicIndex ? 1 : 0);
        pitchName = `${name.replace("_sharp", "#").toUpperCase()}${pitchOffset + pitchBase
            }`;
    }
    return pitchName;
};

export const getScaleInfo = (scale: ScaleType, tonic: NotesType) => {
    const useNotes: NotesType[] = [];
    const tonicIndex = notes.indexOf(tonic);

    const scaleDetails = ScaleDetail[scale].details;
    const scaleLength = scaleDetails.length;

    scaleDetails.forEach((index) => {
        useNotes.push(notes[(tonicIndex + index) % notes.length]);
    });
    return { useNotes, scaleLength, tonicIndex };
};

export const numberToToneTime = (
    n: number,
    beatNumber: number,
    barIndex: number = 0
) => {
    const beatWidth = MidiMinCommonMultiple / beatNumber;
    const bar = Math.floor(n / MidiMinCommonMultiple) + barIndex;
    const gridWidth = beatWidth / 4;
    const quarter = Math.floor(n / beatWidth) % beatNumber;
    const sixteenth = ((n % beatWidth) / gridWidth).toFixed(2);
    return `${bar}:${quarter}:${sixteenth}`;
};

export const midisToToneData = (
    midis: MidiJamType[],
    config: ArrangeConfigType,
    fromBeginning: boolean
) => {
    const result: NoteType[] = [];
    const { scale, tonic, beat } = config;
    const { useNotes, scaleLength, tonicIndex } = getScaleInfo(scale, tonic);

    midis.forEach((jam) => {
        jam.items.forEach((item) => {
            const { trackName } = jam;
            const time = numberToToneTime(
                item.S,
                beat,
                fromBeginning ? jam.barIndex : 0
            );
            const duration = numberToToneTime(item.L, beat);

            result.push({
                time,
                duration,
                pitchName: getPitchNameByIndex(
                    item.y,
                    trackName,
                    scaleLength,
                    useNotes,
                    tonicIndex
                ),
                trackName,
            });
        });
    });
    return result;
};

export function getLoopLength(jams: MidiJamType[]) {
    let left = Infinity;
    let right = -Infinity;
    jams.forEach(({ barIndex, barsNumber }) => {
        left = Math.min(left, barIndex);
        right = Math.max(right, barIndex + barsNumber);
    });
    return right - left;
}

export const panRange = [-1, 1];
export const echoLossRange = [0, 0.8];
export const reverbRange = [0.001, 20];
export const echoIntervalRange = [0, 0.9];

export const getChains = () => {
    const panner = new Tone.Panner(
        (panRange[1] + panRange[0]) / 2
    ).toDestination();
    const reverb = new Tone.Reverb(reverbRange[0]).toDestination();

    const feedbackDelay = new Tone.FeedbackDelay(
        echoIntervalRange[0],
        echoLossRange[0]
    ).toDestination();

    return {
        panner,
        reverb,
        feedbackDelay,
    };
};

export const updateResource = (
    resourceName: ResourceName,
    trackName: TrackNamesType
) =>
    new Promise<void>((resolve) => {
        let player = samplers[resourceName];
        if (!player) {
            player = getTone(musicResources[resourceName], () => resolve());
            player.chain(
                chains[trackName].feedbackDelay,
                chains[trackName].panner,
                chains[trackName].reverb
            );
        } else {
            resolve();
        }
        samplers[resourceName] = player;
        useTrackResources[trackName] = resourceName;
    });

export const defaultSamplers: SamplerStore = {
    AcousticDrum: undefined,
    "808DrumMachine": undefined,
    DrumsKinder: undefined,
    Summer: undefined,
    ElectricBass: undefined,
    SquareBass: undefined,
    ViolinPlayingBass: undefined,
    PianoBass: undefined,
    SawtoothBass: undefined,
    Piano: undefined,
    Marimba: undefined,
    Clarinet: undefined,
    Square: undefined,
    ViolinPlucking: undefined,
    ViolinPlaying: undefined,
    Guitar: undefined,
    Ukulele: undefined,
    Guzheng: undefined,
    Melodica: undefined,
    Recorder: undefined,
    SuperSawtooth: undefined,
    Birdie: undefined,
};

export type ArrangeConfigType = {
    beat: BeatNumber;
    tempo: number;
    tonic: NotesType;
    scale: ScaleType;
};

export type ValueOfChains =
    | {
        type: "panner" | "reverb";
        value: number;
    }
    | {
        type: "feedbackDelay";
        value: [number, number];
    };

export type PlayingDetial = {
    id: string;
    jams: MidiJamType[];
    config: ArrangeConfigType;
    loop: boolean;
    fromBeginning: boolean;
};

export type SamplerStore = Record<
    ResourceName,
    ReturnType<typeof getTone> | undefined
>;

export const useTrackResources: Record<TrackNamesType, ResourceName> = {
    drums: "AcousticDrum",
    melody: "Piano",
    chord: "Guitar",
    bass: "ElectricBass",
};

export const chains: Record<TrackNamesType, ReturnType<typeof getChains>> = {
    drums: getChains(),
    melody: getChains(),
    chord: getChains(),
    bass: getChains(),
};

export const samplers: SamplerStore = defaultSamplers;

export const isMobile = () => {
    return /iPhone|iPod|Android|ios|iPad|BlackBerry|Mobile/i.test(navigator.userAgent)
}