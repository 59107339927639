import React from "react";
import Content1 from "./introContent/content1";
import IntroRow from "./introRow";
import './index.css';
import Content4 from "./introContent/content4";
import Content2 from "./introContent/content2";
import Content3 from "./introContent/content3";

export default function Intro() {
  return (
    <div className="intro">
      <IntroRow
        title={'在手机里就能轻松作曲'}
        notion={'创作从未如此简单！'}
        rotate={-15}
        imageIndex={1}
        flexDirection={'row'}
        content={Content1}
      />

      <IntroRow
        title={'简单易用的编曲小贴士'}
        notion={'轻轻松松！'}
        rotate={20}
        imageIndex={2}
        flexDirection={'row-reverse'}
        content={Content2}
      />

      <IntroRow
        title={'可爱的小动物们演奏你创作的歌曲'}
        notion={'找到你最喜欢的伙伴！'}
        rotate={-10}
        imageIndex={3}
        flexDirection={'row'}
        content={Content3}
      />

      <IntroRow
        title={'小白也能看懂的乐理教程'}
        notion={'乐理原来很容易！'}
        rotate={18}
        imageIndex={4}
        flexDirection={'row-reverse'}
        content={Content4}
      />
    </div>
  )
}