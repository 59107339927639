import { SongType } from "../player/utils";

export const SamplerSummer: SongType = {
  id: 'IeVIsUahJW',
  beat: 4,
  tempo: 90,
  tonic: 'c',
  scale: 'major',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [
      {
        id: 'q9QGlP',
        items: [
          {
            S: 72,
            L: 18,
            y: 11,
            id: 'TqTBok',
          },
          {
            S: 144,
            L: 18,
            y: 11,
            id: 'JqJh0h',
          },
          {
            S: 216,
            L: 18,
            y: 11,
            id: 'DXtn6E',
          },
          {
            S: 18,
            L: 18,
            y: 2,
            id: 'iU5kHM',
          },
          {
            S: 54,
            L: 18,
            y: 2,
            id: 'd3QP6p',
          },
          {
            S: 90,
            L: 18,
            y: 2,
            id: 'R-KIqE',
          },
          {
            S: 126,
            L: 18,
            y: 2,
            id: 'DKvWF9',
          },
          {
            S: 198,
            L: 18,
            y: 2,
            id: 'UxePy8',
          },
          {
            S: 234,
            L: 18,
            y: 2,
            id: 'YpOUbr',
          },
          {
            S: 270,
            L: 18,
            y: 2,
            id: 'eVteD3',
          },
          {
            S: 0,
            L: 18,
            y: 9,
            id: '5ssiv_',
          },
          {
            S: 54,
            L: 18,
            y: 9,
            id: 'Imeh6K',
          },
          {
            S: 108,
            L: 18,
            y: 9,
            id: 'wQxLJA',
          },
          {
            S: 180,
            L: 18,
            y: 9,
            id: 'pCfxRy',
          },
          {
            S: 234,
            L: 18,
            y: 9,
            id: 'LsPFxb',
          },
          {
            S: 162,
            L: 18,
            y: 2,
            id: 'NpGiHe',
          },
          {
            S: 0,
            L: 18,
            y: 11,
            id: 'tfXUfN',
          },
          {
            S: 36,
            L: 18,
            y: 0,
            id: 'zjMt7h',
          },
          {
            S: 108,
            L: 18,
            y: 0,
            id: 'MYqnU6',
          },
          {
            S: 180,
            L: 18,
            y: 0,
            id: 'VYQi1R',
          },
          {
            S: 252,
            L: 18,
            y: 0,
            id: 'Y5Mq2u',
          },
          {
            S: 0,
            L: 18,
            y: 4,
            id: 'KqD9fP',
          },
          {
            S: 54,
            L: 18,
            y: 4,
            id: 'rnfBvn',
          },
          {
            S: 108,
            L: 18,
            y: 4,
            id: 'KlMHEY',
          },
          {
            S: 180,
            L: 18,
            y: 4,
            id: '9D4qdQ',
          },
          {
            S: 234,
            L: 18,
            y: 4,
            id: 'q2Iwi3',
          },
          {
            S: 54,
            L: 18,
            y: 8,
            id: 'gSPJAG',
          },
          {
            S: 180,
            L: 18,
            y: 8,
            id: '2EFDE4',
          },
          {
            S: 72,
            L: 18,
            y: 10,
            id: 'SCiJHt',
          },
          {
            S: 216,
            L: 18,
            y: 10,
            id: 'efrgq_',
          },
          {
            S: 0,
            L: 18,
            y: 7,
            id: '-8DwEI',
          },
          {
            S: 18,
            L: 18,
            y: 6,
            id: '7qwUpF',
          },
          {
            S: 36,
            L: 18,
            y: 5,
            id: 'yg7vG3',
          },
          {
            S: 72,
            L: 18,
            y: 7,
            id: 'tZERpM',
          },
          {
            S: 90,
            L: 18,
            y: 6,
            id: 'yHrZHb',
          },
          {
            S: 108,
            L: 18,
            y: 5,
            id: 'SbOcI4',
          },
          {
            S: 144,
            L: 18,
            y: 7,
            id: 'T09iHx',
          },
          {
            S: 162,
            L: 18,
            y: 6,
            id: '9btOu2',
          },
          {
            S: 180,
            L: 18,
            y: 5,
            id: 'hCEyKt',
          },
          {
            S: 216,
            L: 18,
            y: 7,
            id: 'mffMLR',
          },
          {
            S: 234,
            L: 18,
            y: 6,
            id: 'PsKd_N',
          },
          {
            S: 252,
            L: 18,
            y: 5,
            id: 'H5iovr',
          },
          {
            S: 0,
            L: 18,
            y: 3,
            id: '3Rfq2v',
          },
          {
            S: 180,
            L: 18,
            y: 3,
            id: '0kSxjd',
          },
          {
            S: 0,
            L: 18,
            y: 1,
            id: 'axNd31',
          },
          {
            S: 54,
            L: 18,
            y: 1,
            id: 'TS1Tvq',
          },
          {
            S: 108,
            L: 18,
            y: 1,
            id: 'KRxXOz',
          },
          {
            S: 180,
            L: 18,
            y: 1,
            id: 'mDlJZA',
          },
          {
            S: 234,
            L: 18,
            y: 1,
            id: '7K4AmK',
          },
        ],
        barsNumber: 1,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'drums',
      },
    ],
    melody: [],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'Summer',
    melody: 'Piano',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
