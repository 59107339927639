import { SongType } from "../player/utils";

export const SamplerGuzheng: SongType = {
  id: 'g6ZfYlo4gy',
  beat: 4,
  tempo: 78,
  tonic: 'c',
  scale: 'major',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: 'OwCl-3',
        items: [
          {
            S: 0,
            L: 108,
            y: 12,
            id: 'Y6f-mC',
          },
          {
            S: 108,
            L: 36,
            y: 10,
            id: 'IBgftl',
          },
          {
            S: 144,
            L: 54,
            y: 9,
            id: 'bYfazy',
          },
          {
            S: 198,
            L: 18,
            y: 7,
            id: 'IRMU4d',
          },
          {
            S: 216,
            L: 36,
            y: 9,
            id: 'gMZQri',
          },
          {
            S: 252,
            L: 36,
            y: 10,
            id: 'E0fIKw',
          },
          {
            S: 288,
            L: 216,
            y: 12,
            id: 'Kf6TNH',
          },
          {
            S: 504,
            L: 72,
            y: 13,
            id: 'Y9YWrs',
          },
          {
            S: 576,
            L: 108,
            y: 14,
            id: 'knDzXa',
          },
          {
            S: 684,
            L: 36,
            y: 12,
            id: 'A5YMi9',
          },
          {
            S: 720,
            L: 54,
            y: 13,
            id: 'mW2U86',
          },
          {
            S: 774,
            L: 18,
            y: 12,
            id: '0UawmV',
          },
          {
            S: 792,
            L: 36,
            y: 13,
            id: 'TeqSHY',
          },
          {
            S: 828,
            L: 36,
            y: 14,
            id: 'sT8Wm0',
          },
          {
            S: 864,
            L: 288,
            y: 13,
            id: 'eXej7E',
          },
        ],
        barsNumber: 4,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 20,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'DrumsKinder',
    melody: 'Guzheng',
    chord: 'Piano',
    bass: 'ViolinPlayingBass',
  },
};
