import React, { useEffect, useRef } from "react";
import "./index.css";

export default function Video() {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        function handleResize() {
            if (iframeRef.current) {
                iframeRef.current.style.height = iframeRef.current.getBoundingClientRect().width / (16 / 9) + 'px';
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className="trailer-container">
            <img alt="echo_pull" src={require("../../assets/echo_pull.png")} />

            <iframe
                ref={iframeRef}
                title="video"
                className="trailer-video"
                src="//player.bilibili.com/player.html?aid=906243217&bvid=BV1yM4y1Q7Lr&cid=989977721&page=1"
                style={{ display: "inline" }}
            ></iframe>
        </div>
    );
}
