import { SongType } from "../player/utils";

export const SamplerPianoBass: SongType = {
  id: 'jUXTTKmsoz',
  beat: 4,
  tempo: 90,
  tonic: 'c',
  scale: 'minor',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [],
    chord: [],
    bass: [
      {
        id: 'Qm0bcG',
        items: [
          {
            S: 0,
            L: 18,
            y: 14,
            id: 'YfH5Vt',
          },
          {
            S: 18,
            L: 18,
            y: 10,
            id: '5L2k_l',
          },
          {
            S: 36,
            L: 18,
            y: 11,
            id: 'EKNqWo',
          },
          {
            S: 54,
            L: 18,
            y: 10,
            id: '5jibMd',
          },
          {
            S: 72,
            L: 18,
            y: 14,
            id: 'vzu4Nr',
          },
          {
            S: 90,
            L: 18,
            y: 10,
            id: 'dmPhD4',
          },
          {
            S: 108,
            L: 18,
            y: 11,
            id: '-KYVsY',
          },
          {
            S: 126,
            L: 18,
            y: 10,
            id: 'O0NRVT',
          },
          {
            S: 144,
            L: 18,
            y: 14,
            id: 'C5L-wg',
          },
          {
            S: 162,
            L: 18,
            y: 10,
            id: 'JbMjvr',
          },
          {
            S: 180,
            L: 18,
            y: 11,
            id: 'TMECE_',
          },
          {
            S: 198,
            L: 18,
            y: 10,
            id: '-D9xwW',
          },
          {
            S: 216,
            L: 18,
            y: 14,
            id: 'n3OllU',
          },
          {
            S: 234,
            L: 18,
            y: 10,
            id: '_c7INc',
          },
          {
            S: 252,
            L: 18,
            y: 11,
            id: 'tz6986',
          },
          {
            S: 270,
            L: 18,
            y: 10,
            id: 'cp9pcB',
          },
          {
            S: 288,
            L: 18,
            y: 14,
            id: '33jj_W',
          },
          {
            S: 306,
            L: 18,
            y: 9,
            id: 'nYCWM5',
          },
          {
            S: 324,
            L: 18,
            y: 10,
            id: 'o7g2SC',
          },
          {
            S: 342,
            L: 18,
            y: 9,
            id: 'qC3K2N',
          },
          {
            S: 378,
            L: 18,
            y: 9,
            id: 'OvOY2q',
          },
          {
            S: 396,
            L: 18,
            y: 10,
            id: 'tKe-AJ',
          },
          {
            S: 414,
            L: 18,
            y: 9,
            id: '8h619Y',
          },
          {
            S: 432,
            L: 18,
            y: 14,
            id: 'nwTxHf',
          },
          {
            S: 360,
            L: 18,
            y: 11,
            id: '0TK8TT',
          },
          {
            S: 450,
            L: 18,
            y: 9,
            id: 'mflTZc',
          },
          {
            S: 468,
            L: 18,
            y: 10,
            id: 'qj-ahd',
          },
          {
            S: 486,
            L: 18,
            y: 9,
            id: 'KReMEU',
          },
          {
            S: 504,
            L: 18,
            y: 11,
            id: 'Djm7VU',
          },
          {
            S: 522,
            L: 18,
            y: 9,
            id: '0vZZ6T',
          },
          {
            S: 540,
            L: 18,
            y: 10,
            id: 'txtlf6',
          },
          {
            S: 558,
            L: 18,
            y: 9,
            id: '6sJ-2J',
          },
          {
            S: 576,
            L: 18,
            y: 14,
            id: '_t9F0x',
          },
          {
            S: 594,
            L: 18,
            y: 9,
            id: '6NKSrV',
          },
          {
            S: 612,
            L: 18,
            y: 10,
            id: 'AxFO1s',
          },
          {
            S: 630,
            L: 18,
            y: 9,
            id: '0ZCnyz',
          },
          {
            S: 666,
            L: 18,
            y: 9,
            id: 'zzbcbM',
          },
          {
            S: 684,
            L: 18,
            y: 10,
            id: '7Yyl4k',
          },
          {
            S: 702,
            L: 18,
            y: 9,
            id: 'SFolF9',
          },
          {
            S: 720,
            L: 18,
            y: 14,
            id: 'nwluZh',
          },
          {
            S: 864,
            L: 18,
            y: 14,
            id: 'e5Fbt6',
          },
          {
            S: 954,
            L: 18,
            y: 12,
            id: 'XQEXvE',
          },
          {
            S: 972,
            L: 18,
            y: 13,
            id: 'Ub9NC9',
          },
          {
            S: 990,
            L: 18,
            y: 12,
            id: 'KWDzQ8',
          },
          {
            S: 648,
            L: 18,
            y: 11,
            id: '1yQqxo',
          },
          {
            S: 738,
            L: 18,
            y: 9,
            id: 'nSdUTl',
          },
          {
            S: 756,
            L: 18,
            y: 10,
            id: '17yBNe',
          },
          {
            S: 774,
            L: 18,
            y: 9,
            id: 'U0A6Q_',
          },
          {
            S: 810,
            L: 18,
            y: 9,
            id: 'S-_5sw',
          },
          {
            S: 846,
            L: 18,
            y: 9,
            id: 'XjqV94',
          },
          {
            S: 828,
            L: 18,
            y: 10,
            id: 'QZAjpZ',
          },
          {
            S: 792,
            L: 18,
            y: 11,
            id: 'hqlqf_',
          },
          {
            S: 882,
            L: 18,
            y: 12,
            id: 'MZgJ89',
          },
          {
            S: 900,
            L: 18,
            y: 13,
            id: 'vs4B48',
          },
          {
            S: 918,
            L: 18,
            y: 12,
            id: 'cVIFGM',
          },
          {
            S: 936,
            L: 18,
            y: 10,
            id: 'TJcip4',
          },
          {
            S: 1008,
            L: 18,
            y: 14,
            id: 'z_rMQ7',
          },
          {
            S: 1098,
            L: 18,
            y: 12,
            id: 'vZZ_SI',
          },
          {
            S: 1116,
            L: 18,
            y: 13,
            id: 'ekHWVH',
          },
          {
            S: 1134,
            L: 18,
            y: 12,
            id: 'W3LTks',
          },
          {
            S: 1026,
            L: 18,
            y: 12,
            id: 'sO5X-6',
          },
          {
            S: 1044,
            L: 18,
            y: 13,
            id: 'hSwopx',
          },
          {
            S: 1062,
            L: 18,
            y: 12,
            id: 'bYl85N',
          },
          {
            S: 1080,
            L: 18,
            y: 10,
            id: 'wvCqO7',
          },
        ],
        barsNumber: 4,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'bass',
      },
    ],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 20,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Piano',
    chord: 'Piano',
    bass: 'PianoBass',
  },
};
