import { SongType } from "../player/utils";

export const SamplerRecorder: SongType = {
  id: 'wFKPG2OFuV',
  beat: 4,
  tempo: 72,
  tonic: 'g',
  scale: 'major',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: 'qFxLT9',
        items: [
          {
            S: 0,
            L: 54,
            y: 10,
            id: 'nHdOKG',
          },
          {
            S: 54,
            L: 18,
            y: 12,
            id: 'Dkuc9L',
          },
          {
            S: 72,
            L: 36,
            y: 10,
            id: 'nayvJ8',
          },
          {
            S: 126,
            L: 36,
            y: 9,
            id: 'd01me2',
          },
          {
            S: 162,
            L: 18,
            y: 10,
            id: 'XXOYOn',
          },
          {
            S: 180,
            L: 18,
            y: 12,
            id: 'oCa3kT',
          },
          {
            S: 198,
            L: 18,
            y: 9,
            id: 'hXQ1NG',
          },
          {
            S: 216,
            L: 36,
            y: 10,
            id: 'oPFWO0',
          },
          {
            S: 288,
            L: 54,
            y: 10,
            id: 'kSVwX7',
          },
          {
            S: 342,
            L: 18,
            y: 9,
            id: 'fX_3qL',
          },
          {
            S: 360,
            L: 36,
            y: 10,
            id: 'OsBWD7',
          },
          {
            S: 396,
            L: 18,
            y: 12,
            id: 'Z6ohUf',
          },
          {
            S: 414,
            L: 36,
            y: 11,
            id: 'pz06Cw',
          },
          {
            S: 450,
            L: 18,
            y: 12,
            id: '1kAdQt',
          },
          {
            S: 468,
            L: 18,
            y: 13,
            id: 'TXCPcD',
          },
          {
            S: 486,
            L: 18,
            y: 15,
            id: 'BsFpDk',
          },
          {
            S: 504,
            L: 54,
            y: 14,
            id: 'jL0UrV',
          },
        ],
        barsNumber: 2,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 4.193732689977308,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Recorder',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
