import { SongType } from "../player/utils";

export const SamplerGuitar: SongType = {
  id: 'Lxyw2pFMeS',
  beat: 4,
  tempo: 127,
  tonic: 'c',
  scale: 'chromatic',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: 'jdRwKU',
        items: [
          {
            S: 0,
            L: 36,
            y: 27,
            id: 'cErDEM',
          },
          {
            S: 0,
            L: 36,
            y: 22,
            id: 'Q_j_8N',
          },
          {
            S: 0,
            L: 36,
            y: 17,
            id: 'snYFg_',
          },
          {
            S: 180,
            L: 36,
            y: 17,
            id: 'vUbD0h',
          },
          {
            S: 180,
            L: 36,
            y: 22,
            id: 'uXd2Vx',
          },
          {
            S: 180,
            L: 36,
            y: 27,
            id: 'Hk4UwQ',
          },
          {
            S: 252,
            L: 36,
            y: 17,
            id: 'ICBTRt',
          },
          {
            S: 252,
            L: 36,
            y: 22,
            id: 'dPE68n',
          },
          {
            S: 252,
            L: 36,
            y: 27,
            id: 'J2aF0y',
          },
          {
            S: 72,
            L: 36,
            y: 17,
            id: 'TF9rqT',
          },
          {
            S: 72,
            L: 36,
            y: 22,
            id: 'clDhMD',
          },
          {
            S: 72,
            L: 36,
            y: 27,
            id: '_cIecm',
          },
          {
            S: 324,
            L: 36,
            y: 27,
            id: '5hh7SE',
          },
          {
            S: 324,
            L: 36,
            y: 22,
            id: '35OU88',
          },
          {
            S: 324,
            L: 36,
            y: 17,
            id: 'PIxO2_',
          },
          {
            S: 432,
            L: 36,
            y: 17,
            id: 'zfrI2J',
          },
          {
            S: 432,
            L: 36,
            y: 22,
            id: 'J9u5wL',
          },
          {
            S: 432,
            L: 36,
            y: 27,
            id: 'S2yNqm',
          },
          {
            S: 504,
            L: 36,
            y: 17,
            id: 'oGDn_Y',
          },
          {
            S: 504,
            L: 36,
            y: 22,
            id: 'MHE28K',
          },
          {
            S: 504,
            L: 36,
            y: 27,
            id: '5QLwx3',
          },
          {
            S: 0,
            L: 108,
            y: 31,
            id: 'UEVdMW',
          },
          {
            S: 108,
            L: 18,
            y: 24,
            id: 'x_7Hwg',
          },
          {
            S: 144,
            L: 108,
            y: 24,
            id: 'xZBAto',
          },
          {
            S: 252,
            L: 18,
            y: 19,
            id: 'fW75i1',
          },
          {
            S: 288,
            L: 108,
            y: 19,
            id: 'EOgw5W',
          },
          {
            S: 396,
            L: 18,
            y: 7,
            id: 'loSMSA',
          },
          {
            S: 432,
            L: 108,
            y: 24,
            id: 'W4e6vq',
          },
          {
            S: 540,
            L: 18,
            y: 17,
            id: 'ed4Djb',
          },
          {
            S: 576,
            L: 36,
            y: 29,
            id: 'un96TL',
          },
          {
            S: 576,
            L: 36,
            y: 23,
            id: 'rK2r8G',
          },
          {
            S: 576,
            L: 36,
            y: 19,
            id: 'hYXlOy',
          },
          {
            S: 756,
            L: 36,
            y: 19,
            id: 'lFTWn-',
          },
          {
            S: 756,
            L: 36,
            y: 23,
            id: 'JJCYxI',
          },
          {
            S: 756,
            L: 36,
            y: 29,
            id: 'ajapVs',
          },
          {
            S: 828,
            L: 36,
            y: 19,
            id: 'l5km4M',
          },
          {
            S: 828,
            L: 36,
            y: 23,
            id: 'lrsDaK',
          },
          {
            S: 828,
            L: 36,
            y: 29,
            id: 'GHYaXK',
          },
          {
            S: 648,
            L: 36,
            y: 19,
            id: 'WQmHRG',
          },
          {
            S: 648,
            L: 36,
            y: 23,
            id: 'kDzIiq',
          },
          {
            S: 648,
            L: 36,
            y: 29,
            id: 'oKcuCK',
          },
          {
            S: 900,
            L: 36,
            y: 29,
            id: '52ZIom',
          },
          {
            S: 900,
            L: 36,
            y: 23,
            id: 'HJwSq1',
          },
          {
            S: 900,
            L: 36,
            y: 19,
            id: 'IDBQJh',
          },
          {
            S: 1008,
            L: 36,
            y: 19,
            id: 'ZDosu7',
          },
          {
            S: 1008,
            L: 36,
            y: 23,
            id: 'kdOzLp',
          },
          {
            S: 1008,
            L: 36,
            y: 29,
            id: 'xocFDj',
          },
          {
            S: 1080,
            L: 36,
            y: 19,
            id: 'B5epE6',
          },
          {
            S: 1080,
            L: 36,
            y: 23,
            id: '9x-RgJ',
          },
          {
            S: 1080,
            L: 36,
            y: 29,
            id: '8QNdZW',
          },
          {
            S: 576,
            L: 108,
            y: 26,
            id: 'GUwj6a',
          },
          {
            S: 720,
            L: 108,
            y: 26,
            id: '8scsKQ',
          },
          {
            S: 864,
            L: 108,
            y: 26,
            id: 'yA0YQi',
          },
          {
            S: 972,
            L: 18,
            y: 8,
            id: 'j9r53n',
          },
          {
            S: 1008,
            L: 108,
            y: 26,
            id: 'v7IAfB',
          },
          {
            S: 1116,
            L: 18,
            y: 7,
            id: '3JXMo2',
          },
          {
            S: 684,
            L: 18,
            y: 19,
            id: 'JK8iMC',
          },
          {
            S: 972,
            L: 18,
            y: 19,
            id: 'kzaKcy',
          },
        ],
        barsNumber: 4,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Guitar',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
