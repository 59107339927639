import React from 'react';
import './index.css';
import {useEffect} from 'react';

export default function TermsChi() {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    
    return (
        <div className="terms-container">
        <div className="terms">
        <h1 id="【棒！音乐】用户协议">【棒！音乐】用户协议</h1>
<p>本协议系由您（或称“用户”）与“棒！音乐”（部分平台又称“棒音乐”）网站及相关服务，系指北京棒棒棒科技有限公司及其关联方（或称“我们”）之间就本网站服务的提供、使用而签订。</p>
<h2 id="一、基础约定">一、基础约定</h2>
<ol>
	<p>在使用本网站及服务之前，请您务必审慎阅读、充分理解本协议(如果您未满16周岁，或已满16周岁未满18周岁且不能以自己的劳动收入作为主要收入来源的，请在法定监护人的陪同下阅读本协议），特别是“特别提示”条款（若有）、限制或免除责任条款、隐私保护、法律适用和争议解决条款（包括管辖条款），该类条款将以加粗加黑和/或加下划线等显示形式提示您注意，请务必重点查阅。 </p>
	<p>若您不同意本协议，则您有充分且完全的权利退出使用本网站及服务，您通过网络页面点击注册和/或实际使用本网站及服务的行为即视为您已阅读、理解并同意接受本协议。如果您对本协议有任何的疑问、投诉、意见和建议，欢迎您通过本协议所附联系方式与我们沟通反馈，我们十分乐意为您解答。 </p>
	<p>协议范围：考虑到互联网服务的不确定性以及产品频繁迭代更新，为了更全面的界定您与我们之间的权利义务，本协议包括我们根据法律法规规定以及本协议制定的其他政策、规则、公告声明等，如内容发生不一致或有矛盾，以专门适用于某个业务场景的协议条款为准。 </p>
	<p>服务范围：我们可能通过不断丰富的网页界面向您提供本协议项下的网站服务，包括但不限于移动应用程序（“APP”）、PC端网站、小程序以及其他形式。具体以我们实时发布的服务功能界面范围为准。 </p>
	<p>修改与更新：我们有权遵照法律法规规定，在必要时修改本协议，更新后的协议条款将代替原来的协议并在法律规定的期限届满后正式生效，您可以在相关服务页面查阅最新版本的协议条款。本协议修改、变更后，如果您继续使用本网站及服务的，即视为您已接受修改后的协议。如果您不接受修改后的协议，则您有权利停止使用本网站及服务。
		<h2 id="二、使用本网站及服务前提">二、使用本网站及服务前提</h2>
	</p>
	<p>年龄限制：您向我们保证您在使用本网站及服务时已年满18周岁，或虽未年满18周岁，但已年满16周岁并能以自己的劳动收入为主要生活来源。如果您不符合上述条件，您应和您的监护人一起阅读本协议以确保明确您有能力了解本协议内容，在监护人同意之前，您不能且不应使用本网站及服务。 </p>
	<p>网站更新：为了保证本网站及服务的安全性和功能之一致性，我们有权不经向您特别通知而对网站进行更新，或者对网站的部分功能效果进行改变、限制甚至终止。 </p>
	<p>用户不可以对本网站运行过程中客户端与服务器端的交互数据进行复制、更改、修改、挂接运行或创作任何衍生作品，禁止行为包括但不限于使用插件、外挂或非经授权的第三方工具/服务接入本网站和相关系统、未经授权反向工程本网站程序的全部或部分等。 </p>
	<p>使用限制：您同意仅基于本协议之目的使用本网站及服务。如果您对本网站及服务的使用或其他行为有意或无意地威胁我们提供本网站或其他系统的能力，我们有权采取一切合理措施保护本网站和系统，当中可能包括暂停您访问本网站及服务。如您屡次违反限制，可能会导致本网站服务的终止。
		<h2 id="三、用户账号规则">三、用户账号规则</h2>
	</p>
	<p>您在注册或使用本网站及服务时可能需要提供一些必要的信息。您须保证所填写及所提供的资料真实、准确、完整，否则无法使用本服务，或在使用过程中受到限制，甚至影响您是否能够使用本网站及其特定功能。对于因您提供的信息不真实、不准确或不完整导致的责任和损失由您承担。 </p>
	<p>本网站有权根据您所提供资料的真实性、准确性、完整性以及是否符合本网站及服务规定的其他条件，决定是否为您提供服务。您在使用本网站及服务过程中，所提供的信息发生变动，应及时进行变更。</p>
	<p>您注册成功后，我们将为您配置帐号，该帐号之所有权归我们所有，用户在本协议有效期内仅享有使用权。未经本网站同意，用户的帐号仅供用户本人使用，帐号信息和权限不得以转让、赠与、借用、售卖、授权或租用等方式许可给第三方使用或和第三方共同控制、使用。 </p>
	<p>如果您需要注销您的帐号，您有权通过本网站公布的方式、程序进行，我们将在核实您的身份并清理帐号资产（例如已充值或已获得的虚拟资产等）及纠纷争议后，为您提供帐号注销服务。在注销帐号之后，我们将停止为您提供任何服务。当然，我们也保留权利在您的帐号符合回收的如下情况下，直接回收并注销您的帐号并停止服务：a.未通过实名认证；b.连续12个月未曾登陆、使用本网站及服务。 </p>
	<p>本网站用户一经注册，即可能可以在本网站及我们关联公司运营的其它产品，或者合作伙伴等第三方平台中通行使用。在用本网站帐号登录并使用其他产品或服务时，除遵守本协议约定外，还应遵守该等产品的服务协议的约定。本网站及服务和其他产品对可能出现的纠纷在法律规定和约定的范围内各自承担责任。 </p>
	<p>为客户体验及功能服务考虑，您在使用不同服务时被要求填写的信息内容可能不完全一致，因此，在使用更高级别服务时，为了给您提供更好的服务及为了您的登录帐号安全，可能需要您补充完整信息后方可使用。 </p>
	<p>您有责任自行负责保管帐号的用户名和密码等信息，否则因该等事项引发的法律责任由用户自行承担。凡使用本网站及服务登录帐号和登录凭证的行为，本网站及服务视为您本人的操作，操作所产生的电子信息记录均为本网站及服务用户行为的有效凭据。
		<h2 id="四、用户行为规范">四、用户行为规范</h2>
	</p>
	<p>您保证合理地使用本网站，并接受本协议和遵守任何有效的法律法规。 </p>
	<p>行为禁止：您可在本协议约定的范围内使用本网站及服务，您不得利用本网站及服务从事以下行为： </p>
	<p>超出授权或恶意使用本网站； </p>
	<p>赠与、借用、租用、转让或售卖本网站，修改、破坏本网站原状； </p>
	<p>利用本网站发表、传送、传播、储存危害国家安全、国家统一、社会稳定的内容，或侮辱诽谤、色情、暴力、引起他人不安及任何违反国家法律法规政策的内容或者设置含有上述内容的网名、角色名，发布、传送、传播广告信息、营销信息及垃圾信息等； </p>
	<p>利用本网站侵害他人知识产权、肖像权、隐私权、名誉权等合法权利； </p>
	<p>恶意虚构事实、隐瞒真相以误导、欺骗他人； </p>
	<p>进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账户；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本“网站”系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏本“网站”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称；利用本网站及服务上传任何病毒，木马，或者蠕虫等危害网络健康的内容； </p>
	<p>对本网站进行反向工程、反向编译或反向汇编或以其它方式企图发现本网站的源代码和算法，未经许可修改、禁用网站的任何功能或创建基于网站的衍生作品。去除本网站或文档上的任何所有权声明或标签，或将其他网站与本网站合并； </p>
	<p>进行任何破坏我们提供服务公平性或者其他影响应用正常秩序的行为，如主动或被动刷分、合伙作弊、使用外挂或者其他的作弊网站、利用BUG（又叫“漏洞”或者“缺陷”）来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊网站、BUG公之于众； </p>
	<p>从事其他法律法规、政策及公序良俗、社会公德禁止的行为以及侵犯其他个人、公司、社会团体、组织的合法权益的行为。 </p>
	<p>信息内容的使用规范 未经我们书面许可，您不得自行或授权、允许、协助任何第三人对本网站中信息内容进行如下行为：</p>
	<p>复制、读取、采用本网站及相关服务的信息内容，用于任何形式的商业用途；</p>
	<p>擅自编辑、整理、编排本网站及相关服务的信息内容后在本网站及相关服务的源页面以外的渠道进行展示； </p>
	<p>采用不正当方式，自行或协助第三人对本网站及相关服务的信息内容产生流量、阅读量引导、转移、劫持等不利影响； </p>
	<p>其他非法获取本网站信息内容或其他不正当竞争行为的行为。
		<h2 id="五、个人信息保护与隐私政策">五、个人信息保护与隐私政策</h2>
	</p>
	<p>尊重用户隐私并保护您的个人信息安全是我们的一贯态度，本网站及服务将会采取合理的措施保护您的个人信息与隐私。我们承诺，除非获得用户同意，本网站及服务不会收集、使用其提供服务所必需以外的用户个人信息或者将信息用于提供服务之外的目的。 </p>
	<p>2.在遵守本协议项下特别约定的个人信息保护与隐私政策外，我们希望您认真并完整阅读我们特别针对本网站而制定并适时发布的《隐私政策》，这将更有助于保障您的个人信息。
		<h2 id="六、服务费用">六、服务费用</h2>
	</p>
	<p>经您同意，我们有权向用户提供付费产品/或服务，用户有权自愿选择是否支付服务费用。若用户不选择支付服务费用则用户仅有权享受我们网站上非付费的产品/或服务，若用户选择支付服务费用则用户有权享受所支付服务费用对应的产品/或服务。用户不得擅自录制并传播付费产品/或服务，否则我们将追究用户的违约责任，并有权要求违约的用户赔偿我们因此遭受的一切损失（包括但不限于律师费、诉讼费、保全费、差旅费及我们向第三方承担的赔偿金等）。</p>
	<p>【棒！音乐】所提供的虚拟付费服务为虚拟商品，故不支持退款，请您理解。
		<h2 id="七、信息或广告推送">七、信息或广告推送</h2>
您同意在接受我们提供服务的同时，允许我们在遵守法律法规的前提下自行或由第三方广告商向您展示广告、推广或宣传信息（包括商业与非商业信息）。如您对推荐的广告或信息不感兴趣，您可以基于我们提供的相关技术选项，控制系统向您展示或不展示/减少展示相关类型的广告或信息。
        <h2 id="八、知识产权等权益">八、知识产权等权益</h2>
	</p>
</ol>
<p>1.我们对本网站中所有图文、视频内容享有著作权、其他知识产权及衍生品开发的权利。
2.基于对数据的合法加工而获得的具有竞争性的数据权益，除法律法规另有规定外，我们享有独立的使用权益而无须获得您的同意。</p>
<h2 id="九、免责声明">九、免责声明</h2>
<ol>
	<p>我们对于您自本网站而获得的所有他人、第三方的信息、内容或者广告宣传等任何资讯（以下统称“信息”），除法律明确规定外，不保证真实、准确和完整性。如果任何单位或者个人通过上述“信息”而进行任何行为，您须自行甄别真伪和谨慎预防风险。您在接受本网站及服务时，有可能会接触到令人不快、不适当或令人厌恶的内容，在任何情况下，我们均不对任何此等内容承担任何责任。无论何种原因，我们不对任何非与我们直接发生的交易或行为承担任何直接、间接、附带或衍生的损失和责任。 </p>
	<p>鉴于互联网服务的特殊性，您理解并同意我们在以下情况下无需对您所遭受的损失（包括但不限于财产、收益、数据资料等方面的损失或其它无形损失）承担责任： （1）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素导致本网站及服务障碍不能正常运作 （2）由于计算机病毒、木马、其他恶意程序、黑客攻击、电信部门及网络运营公司技术调整或故障、系统维护等原因而造成本网站及服务中断或延迟； （3）由于法律法规的变更、司法机关及行政机关等的命令、裁定等原因而导致的本网站及服务中断、终止或延迟。 </p>
	<p>尽管本协议中可能含有相悖的规定，我们对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您在使用期内因使用我们服务而支付给我们的费用(如有)。 </p>
	<p>关于信息内容的投诉或举报。若您在使用本网站过程中，不慎受到合法权益的侵犯，您有权通知我们采取必要措施进行处置。若您在使用本网站过程中，发现存在违法违规或违反本服务相关规则的情况，您也有权“路见不平”向我们发起举报，我们亦会及时采取必要措施（删除、屏蔽、断开链接或限制使用功能等）进行处置。 </p>
	<p>内容维权授权。在法律法规允许的范围内，您同意并授权我们就侵犯您合法权益的行为（包括但不限于私自复制、使用、编辑、抄袭等行为）采取任何形式的法律行动，包括但不限于投诉、诉讼等必要的维权措施。
		<h2 id="十、违约责任">十、违约责任</h2>
	</p>
	<p>独立判断：如果我们发现、收到他人举报或投诉您违反本协议约定或违反法律法规和国家有关规定的，我们有权对此独立进行判断，对您采取警示、终止/中止/限制浏览器使用功能或提出损害赔偿等措施。 </p>
	<p>若您的行为给我们造成损失的（包括但不限于直接损失、名誉损失、第三方的罚款、索赔等），我们有权全额向您追偿，如您在本网站中有保证金、虚拟货币等财产或优惠券等虚拟权益的，我们有权冻结。</p>
	<p>若您违反本协议的约定而与第三方产生纠纷的或因使用第三方服务而产生纠纷的，一切后果需由您自行承担，与我们无涉，但我们将根据法律规定在力所能及的范围内协助您处理此类纠纷。
		<h2 id="十一、法律适用与管辖">十一、法律适用与管辖</h2>
	</p>
	<p>法律适用：本协的订立、执行、解释及争议的解决均适用中华人民共和国（大陆）法律法规。 </p>
	<p>管辖：您与我们因本协议而产生的任何纠纷，双方应本着友好的态度协商解决，解决无果的情况下，任何一方有权将争议提交至我们住所地人民法院诉讼解决。
		<h2 id="十二、如何联系我们">十二、如何联系我们</h2>
如果您有任何的疑问、投诉、意见和建议，欢迎您与我们沟通反馈。 我们的联系邮箱：
		<a href="mailto:&#104;&#105;&#x40;&#x6e;&#x69;&#99;&#x65;&#115;&#116;&#x69;&#x63;&#107;&#x2e;&#x69;&#111;">&#104;&#105;&#x40;&#x6e;&#x69;&#99;&#x65;&#115;&#116;&#x69;&#x63;&#107;&#x2e;&#x69;&#111;</a>
	</p>
</ol>


        </div>
        </div>
    )
}
