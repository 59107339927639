import { Application, Graphics, Sprite, Texture } from "pixi.js-legacy";
import Player from "../player";
import DrumsAnimal from "../player/drumsAnimal";
import NotDrumsAnimal from "../player/notDrumsAnimal";
import { animalConfigs, animalsDetails, isMobile, resourceLoader, roleNames } from "../player/utils";
import { ResourceSamplers } from "../samplers";

export default function PixiStage(canvas: HTMLCanvasElement) {
    const app = new Application({
        autoStart: false,
        resolution: 1,
        backgroundAlpha: 0,
        view: canvas!,
        width: 1920,
        height: 1080,
    });

    const animals = roleNames.map((roleName, i) => {
        const config = animalConfigs[roleName];
        const animal = config.isDrums
            ? DrumsAnimal(roleName, [0.5, 0])
            : NotDrumsAnimal(roleName, undefined, [0.5, 0]);
        animal.result.x = 960;
        animal.result.y = -50;
        animal.result.scale.set(1.8);
        animal.result.cursor = "audio";
        return animal;
    });

    const stage = new Sprite(
        Texture.from(require("../../../../assets/stage.png"))
    );
    stage.scale.set(2);
    stage.anchor.set(0.5);
    stage.position.set(960, 800);

    const background = new Graphics();
    background.beginFill(0x000000);
    background.drawRect(0, 0, 1920, 1080);
    background.endFill();
    background.alpha = 0;
    background.interactive = true;
    background.cursor = "play";

    const name = new Sprite(Texture.EMPTY);
    name.scale.set(2);
    name.anchor.set(0.5);
    name.position.set(960, 930);

    const playIcon = `url('${require(`../../../../assets/cursors/cursor_play.png`)}') 30 30,auto`;
    const pauseIcon = `url('${require("../../../../assets/cursors/cursor_pause.png")}') 30 30,auto`;
    const audioIcon = `url('${require("../../../../assets/cursors/cursor_voice.png")}') 30 30,auto`;
    app.renderer.plugins.interaction.cursorStyles["play"] = playIcon;
    app.renderer.plugins.interaction.cursorStyles["pause"] = pauseIcon;
    app.renderer.plugins.interaction.cursorStyles["audio"] = audioIcon;

    let animalIndex = 0;
    let playing = false;
    let currentSongId: string
    const player = Player()

    const handlerIndexUpdate = () => {
        animals.forEach((item, i) => {
            item.result.visible = i === animalIndex;
            item.active = i === animalIndex;
        });
        name.texture = Texture.from(`stage_name_${roleNames[animalIndex]}`);
        if (playing) {
            player.stop()
            preparePlay()
            player.play()
        }
    };

    const handlerPrev = () => {
        animalIndex = (animalIndex - 1 + roleNames.length) % roleNames.length;
        handlerIndexUpdate();
    };

    const handlerNext = () => {
        animalIndex = (animalIndex + 1) % roleNames.length;
        handlerIndexUpdate();
    };

    const preparePlay = () => {
        const resource = animalsDetails[roleNames[animalIndex]].resources[0]
        const samplerSong = ResourceSamplers[resource]
        if (currentSongId !== samplerSong.id) {
            currentSongId = samplerSong.id;
            player.updateBySong(samplerSong)
            player.updateMidis({
                id: samplerSong.id,
                jams: Object.values(samplerSong.jams).flat(),
                config: samplerSong,
                loop: true,
                fromBeginning: false,
            })
        }
    }

    const handlerBackgroundClick = () => {
        playing = !playing;
        background.cursor = playing ? "pause" : "play";
        if (playing) {
            preparePlay()
            player.play()
        } else {
            player.stop()
        }
    };

    resourceLoader(
        roleNames.map((roleName, i) => ({
            name: `stage_name_${roleName}`,
            url: require(`../../../../assets/stage_name_eng/stage_name_${roleNames[i]}.png`),
        }))
    ).then(() => {
        handlerIndexUpdate();
        app.start();
        app.stage.addChild(
            background,
            stage,
            name,
            ...animals.map((animal) => animal.result)
        );
    });

    if (!isMobile()) {
        background.on("pointertap", handlerBackgroundClick);
    }

    player.setHandlerNotePlayCallback((v) => {
        animals.forEach((animal) => {
            animal.playNote(v)
        })
    })

    return {
        handlerPrev,
        handlerNext,
        togglePlay: () => {
            handlerBackgroundClick()
            return playing
        }
    };
}
