import { SongType } from "../player/utils";

export const SamplerBirdie: SongType = {
  id: 'dwLLudkxF-',
  beat: 4,
  tempo: 80,
  tonic: 'c',
  scale: 'major',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: '5gW-Pw',
        items: [
          {
            S: 0,
            L: 24,
            y: 7,
            id: '4audQf',
          },
          {
            S: 24,
            L: 24,
            y: 6,
            id: '9mqt5v',
          },
          {
            S: 48,
            L: 24,
            y: 5,
            id: 'Hkg1CT',
          },
          {
            S: 72,
            L: 48,
            y: 2,
            id: '8PGY3r',
          },
          {
            S: 144,
            L: 24,
            y: 3,
            id: 'XIT9oS',
          },
          {
            S: 168,
            L: 24,
            y: 6,
            id: 'wTFMHS',
          },
          {
            S: 192,
            L: 24,
            y: 5,
            id: 'R_dDhs',
          },
          {
            S: 216,
            L: 48,
            y: 7,
            id: 'BTfF0k',
          },
        ],
        barsNumber: 1,
        gridNumber: 3,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0.14287567451422686,
      echo_interval: 0.06990650197112591,
      reverb: 7.324708766457439,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Birdie',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
