import React from "react";
import './index.css';

export default function SignUpPopUp(params:{onRequestClose:()=>void}) {
    return(
        <div className="tiny-letter-sign-up">
          <img alt="background" src={require('../../assets/sign_up_img.png')} />
          <p>Join the Nice Stick email list to join closed beta
            <img alt="heart" src={require('../../assets/sign_up_heart.png')} />
          </p>
          <form action="https://tinyletter.com/NiceStick" method="post" target="popupwindow" 
          onSubmit={()=>{
            window.open('https://tinyletter.com/NiceStick', 'popupwindow', 'scrollbars=yes,width=800,height=600');
            params.onRequestClose();
            return true
          }}>
  
          <p><input className="email-input" type="text" name="email" placeholder="Your email address.."></input></p>
          <input className="submit-button" type="submit" value="SIGN IT UP" /></form>
        </div>
    )
}
