import { SongType } from "../player/utils";

export const SamplerViolinPlayingBass: SongType = {
  id: 'h7-vvf5NTp',
  beat: 4,
  tempo: 60,
  tonic: 'c',
  scale: 'chromatic',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [],
    chord: [],
    bass: [
      {
        id: 'FMJ-qT',
        items: [
          {
            S: 0,
            L: 54,
            y: 25,
            id: 'AaYLX7',
          },
          {
            S: 54,
            L: 18,
            y: 21,
            id: '65Fw2O',
          },
          {
            S: 90,
            L: 54,
            y: 13,
            id: 'LAkgxQ',
          },
          {
            S: 144,
            L: 54,
            y: 12,
            id: 'A12maw',
          },
          {
            S: 198,
            L: 18,
            y: 15,
            id: 'wF3Las',
          },
          {
            S: 216,
            L: 18,
            y: 17,
            id: 'nDgQ3r',
          },
          {
            S: 234,
            L: 54,
            y: 20,
            id: 'WmadCL',
          },
          {
            S: 72,
            L: 18,
            y: 18,
            id: 'vZU24J',
          },
          {
            S: 288,
            L: 54,
            y: 21,
            id: 'xK3H8D',
          },
          {
            S: 342,
            L: 18,
            y: 18,
            id: 'R8tiBc',
          },
          {
            S: 360,
            L: 18,
            y: 13,
            id: '548j0B',
          },
          {
            S: 378,
            L: 54,
            y: 9,
            id: 'cny9um',
          },
          {
            S: 432,
            L: 54,
            y: 8,
            id: 'rJJDCA',
          },
          {
            S: 486,
            L: 18,
            y: 12,
            id: 'E6j3o2',
          },
          {
            S: 504,
            L: 18,
            y: 13,
            id: 'Hla8wg',
          },
          {
            S: 522,
            L: 54,
            y: 15,
            id: 'wzjsV5',
          },
        ],
        barsNumber: 2,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'bass',
      },
    ],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Piano',
    chord: 'Piano',
    bass: 'ViolinPlayingBass',
  },
};
