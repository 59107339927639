import { AnimatedSprite, Container, Texture } from 'pixi.js-legacy';
import { Draw, Player, Transport } from 'tone';
import { animalConfigs, animalsDetails, DrumsAnimalConfig, DrumsIcons, DrumsNamesType, NoteType, resourceLoader, ResourceName, RolesNameType, throttle, } from './utils';

export default function DrumsAnimal(
  roleName: RolesNameType,
  anchor: [number, number] = [0.5, 0]
) {
  const { idle, actions, idleZIndexTop, tap } = animalConfigs[roleName] as DrumsAnimalConfig;

  let active = true;
  const { resources } = animalsDetails[roleName];
  const realActions = actions.filter(({ frames }) => frames > 0);
  const audioPlayers = new Array(tap.audios).fill('').map(
    (_, i) => {
      return new Player(require(`../../../../assets/animals/${roleName}/audio/${i}.mp3`)).toDestination()
    }
  )

  const idleNames = new Array(idle).fill('').map((_, i) => {
    const path = `${roleName}/idle/${i}`;
    return {
      name: path,
      url: require(`../../../../assets/animals/${path}.png`),
    };
  });

  const idleTextures = () => idleNames.map((texture) => Texture.from(texture.name));

  const tapTextures = new Array(tap.frames).fill('').map((_, i) => {
    const path = `${roleName}/tap/${i}`;
    return Texture.from(
      require(`../../../../assets/animals/${path}.png`)
    );
  });

  const actionTextures = actions
    .map(({ frames }, i) =>
      new Array(frames).fill('').map((_, j) => {
        const path = `${roleName}/act${i + 1}/${j}`;
        return Texture.from(
          require(`../../../../assets/animals/${path}.png`)
        );
      })
    )
    .filter((item) => item.length > 0);

  const container = new Container();
  container.interactive = true;
  container.buttonMode = true;

  const actionArray = realActions.map((action, i) => {
    const sprite = new AnimatedSprite(actionTextures[i]);
    sprite.anchor.set(anchor[0], anchor[1]);
    sprite.loop = false;
    sprite.animationSpeed = 0.4;
    sprite.zIndex = realActions.length - 1 - i;
    container.addChild(sprite);

    const playAction = throttle<void>(() => {
      sprite.gotoAndPlay(0);
    }, 60)

    const handlerNotePlay = ({
      pitchName,
      trackName,
      resourceName,
    }: NoteType & { resourceName: ResourceName }) => {
      if (trackName !== 'drums') return;
      const noteIndex = Number(pitchName.match(/\d+/)?.[0]);

      if (active && resources.indexOf(resourceName) !== -1) {
        for (let k = 0; k < resources.length; k += 1) {
          if (
            action.drums.indexOf(
              DrumsIcons[resources[k] as DrumsNamesType][noteIndex]
            ) !== -1
          ) {
            playAction();
            break;
          }
        }
      }
    };

    return handlerNotePlay
  });

  const idleSprite = new AnimatedSprite([Texture.EMPTY]);
  idleSprite.anchor.set(anchor[0], anchor[1]);
  idleSprite.loop = false;
  idleSprite.animationSpeed = 0.4;

  container.sortableChildren = true;
  idleSprite.zIndex = idleZIndexTop ? realActions.length + 1 : -1;
  container.addChild(idleSprite);

  const playIdle = () => {
    if (idleSprite.playing) return;
    idleSprite.textures = idleTextures();
    idleSprite.gotoAndPlay(0);
  };

  const playAudio = () => {
    idleSprite.textures = tapTextures;
    idleSprite.gotoAndPlay(0);
    if (tap.audios === 0) return;
    audioPlayers[Math.floor(Math.random() * tap.audios)].start()
  };

  idleSprite.interactive = true;
  container.on('pointertap', playAudio);

  resourceLoader(idleNames).then(() => {
    idleSprite.textures = idleTextures();

    Transport.scheduleRepeat((time) => {
      Draw.schedule(() => {
        if (active) {
          playIdle();
        }
      }, time);
    }, '0:1:0');
  });

  return {
    result: container,
    set active(value: boolean) {
      active = value;
    },
    get active() {
      return active;
    },
    playNote: (v: NoteType & { resourceName: ResourceName; }) => {
      actionArray.forEach((action) => action(v))
    }
  };
}
