import { SongType } from "../player/utils";

export const Sampler808DrumMachine: SongType = {
  id: 'g3ZfYlo4gy',
  beat: 4,
  tempo: 146,
  tonic: 'c',
  scale: 'major',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [
      {
        id: 'EiiniC',
        items: [
          {
            id: 'HRaOqe',
            y: 8,
            S: 0,
            L: 18,
          },
          {
            id: 'Bpmjzg',
            y: 8,
            S: 36,
            L: 18,
          },
          {
            id: 'NIoDq-',
            y: 8,
            S: 72,
            L: 18,
          },
          {
            id: 'u_MZhF',
            y: 8,
            S: 108,
            L: 18,
          },
          {
            id: 'U4tAvT',
            y: 8,
            S: 144,
            L: 18,
          },
          {
            id: '7gtpDj',
            y: 8,
            S: 162,
            L: 18,
          },
          {
            id: 'Fjr38l',
            y: 8,
            S: 180,
            L: 18,
          },
          {
            id: 'M0A6uj',
            y: 8,
            S: 252,
            L: 18,
          },
          {
            id: '82HSvE',
            y: 8,
            S: 288,
            L: 18,
          },
          {
            id: '30a-ug',
            y: 8,
            S: 324,
            L: 18,
          },
          {
            id: 'N2dg5v',
            y: 8,
            S: 360,
            L: 18,
          },
          {
            id: 'B6K_3M',
            y: 8,
            S: 396,
            L: 18,
          },
          {
            id: '_LL-lw',
            y: 8,
            S: 432,
            L: 18,
          },
          {
            id: 'pCKCi7',
            y: 8,
            S: 468,
            L: 18,
          },
          {
            id: 'tQ580P',
            y: 8,
            S: 504,
            L: 18,
          },
          {
            id: 'E_zEFi',
            y: 8,
            S: 540,
            L: 18,
          },
          {
            id: 'qNC0rc',
            y: 11,
            S: 0,
            L: 18,
          },
          {
            id: 'FGpeCK',
            y: 11,
            S: 108,
            L: 18,
          },
          {
            id: '3mIs6l',
            y: 11,
            S: 216,
            L: 18,
          },
          {
            id: 'wKOrzR',
            y: 11,
            S: 360,
            L: 18,
          },
          {
            S: 144,
            L: 18,
            y: 9,
            id: 'IHklwT',
          },
          {
            S: 432,
            L: 18,
            y: 9,
            id: 'SLA1PU',
          },
          {
            S: 216,
            L: 18,
            y: 8,
            id: '2U2uO_',
          },
        ],
        barsNumber: 2,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'drums',
      },
    ],
    melody: [],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: '808DrumMachine',
    melody: 'Marimba',
    chord: 'Piano',
    bass: 'ViolinPlayingBass',
  },
};
