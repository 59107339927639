import React from "react"
import './index.css';

export default function Characters() {
    const avatarChirpie = require('../../assets/characters_chi/avatar_chirbie.png')
    const avatarDali = require('../../assets/characters_chi/avatar_dali.png')
    const avatarDonut = require('../../assets/characters_chi/avatar_donut.png')
    const avatarEchoDelay = require('../../assets/characters_chi/avatar_echo&delay.png')
    const avatarFann = require('../../assets/characters_chi/avatar_fann.png')
    const avatarKamo = require('../../assets/characters_chi/avatar_kamo.png')
    const avatarFuyu = require('../../assets/characters_chi/avatar_fuyu.png')
    const avatarYoru = require('../../assets/characters_chi/avatar_yoru.png')
    const avatarPonzi = require('../../assets/characters_chi/avatar_ponzi.png')
    const avatarMaggie = require('../../assets/characters_chi/avatar_maggie.png')
    const avatarMarsh = require('../../assets/characters_chi/avatar_marsh.png')
    const avatarPisces = require('../../assets/characters_chi/avatar_pisces.png')
    const avatarRappit = require('../../assets/characters_chi/avatar_rappit.png')
    const avatarSandy = require('../../assets/characters_chi/avatar_sandy.png')
    const avatarShojo = require('../../assets/characters_chi/avatar_shojo.png')
    const avatarSpike = require('../../assets/characters_chi/avatar_spike.png')
    // const avatarWako = require('../../assets/characters/avatar_wako.png')

    return (
        <div className="div-center">
            <div className="character-container">
                <div className="items-title">
                    小动物们陪你创作音乐
                </div>
                <div>
                    <img alt="chirpie" src={avatarChirpie} />
                    <img alt="dali" src={avatarDali} />
                    <img alt="donut" src={avatarDonut} />
                    <img alt="echo_delay" src={avatarEchoDelay} />
                    <img alt="fann" src={avatarFann} />
                    <img alt="kamo" src={avatarKamo} />
                    <img alt="fuyu" src={avatarFuyu} />
                    <img alt="yoru" src={avatarYoru} />
                    <img alt="ponzi" src={avatarPonzi} />
                    <img alt="maggie" src={avatarMaggie} />
                    <img alt="marsh" src={avatarMarsh} />
                    <img alt="pisces" src={avatarPisces} />
                    <img alt="rappit" src={avatarRappit} />
                    <img alt="sandy" src={avatarSandy} />
                    <img alt="shojo" src={avatarShojo} />
                    <img alt="spike" src={avatarSpike} />
                </div>
                <div className="items-title">
                    还有更多等待你遇见……
                </div>
            </div>
        </div>
    )
}