import React from "react";
import {useRef, useEffect} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function Content1(){
    const padRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        const pad = padRef.current;
        const container = containerRef.current;
  
        gsap.fromTo(pad, {x:-80, y:80}, {x:0, y:0, duration: 0.6, scrollTrigger:{
          trigger:container
        }})
      }, [])

    return(
        <div className="content1" ref={containerRef}>
                <img ref={padRef} alt='midi_pad' src={require('../../../assets/midi_pad.png')} />
        </div>
    )
}