import { SongType } from "../player/utils";

export const SamplerSquareBass: SongType = {
  id: 'NEKZBopVOZ',
  beat: 4,
  tempo: 90,
  tonic: 'c',
  scale: 'major',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [],
    chord: [],
    bass: [
      {
        id: 'Inokh8',
        items: [
          {
            S: 0,
            L: 18,
            y: 9,
            id: '2cGEoY',
          },
          {
            S: 36,
            L: 36,
            y: 9,
            id: 'NrBk6m',
          },
          {
            S: 72,
            L: 54,
            y: 7,
            id: 'Oo7ES-',
          },
          {
            S: 126,
            L: 18,
            y: 9,
            id: 'Pbm9_r',
          },
          {
            S: 162,
            L: 18,
            y: 12,
            id: 'O7-5AG',
          },
          {
            S: 180,
            L: 18,
            y: 12,
            id: 'J15e7s',
          },
          {
            S: 216,
            L: 36,
            y: 12,
            id: 'fiJkfe',
          },
          {
            S: 252,
            L: 36,
            y: 10,
            id: 'r5ErMg',
          },
          {
            S: 288,
            L: 18,
            y: 9,
            id: 'MB1gPL',
          },
          {
            S: 324,
            L: 36,
            y: 9,
            id: 'weIHdJ',
          },
          {
            S: 360,
            L: 54,
            y: 7,
            id: 'rPEfS3',
          },
          {
            S: 414,
            L: 18,
            y: 9,
            id: 'E2F-HL',
          },
          {
            S: 450,
            L: 18,
            y: 12,
            id: 'syxggR',
          },
          {
            S: 468,
            L: 18,
            y: 12,
            id: '1kgAMy',
          },
          {
            S: 504,
            L: 54,
            y: 12,
            id: 'DHMtc2',
          },
        ],
        barsNumber: 2,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'bass',
      },
    ],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 9.989913959315244,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'Piano',
    chord: 'Piano',
    bass: 'SquareBass',
  },
};
