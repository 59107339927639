import { SongType } from "../player/utils";

export const SamplerViolinPlaying: SongType = {
  id: 'hbnIozSKml',
  beat: 4,
  tempo: 140,
  tonic: 'c',
  scale: 'chromatic',
  tracks: {
    drums: [],
    melody: [],
    chord: [],
    bass: [],
  },
  jams: {
    drums: [],
    melody: [
      {
        id: 'qdkPmv',
        items: [
          {
            S: 0,
            L: 36,
            y: 15,
            id: '4kbIXJ',
          },
          {
            S: 54,
            L: 18,
            y: 15,
            id: 'XOOd-L',
          },
          {
            S: 72,
            L: 18,
            y: 15,
            id: 'hOZK_o',
          },
          {
            S: 90,
            L: 18,
            y: 12,
            id: 'Pfcstr',
          },
          {
            S: 108,
            L: 18,
            y: 13,
            id: 'CsZt_f',
          },
          {
            S: 126,
            L: 18,
            y: 15,
            id: 'K6bE_l',
          },
          {
            S: 144,
            L: 36,
            y: 8,
            id: 'HRRUbh',
          },
          {
            S: 198,
            L: 18,
            y: 20,
            id: 'ypTrBO',
          },
          {
            S: 216,
            L: 18,
            y: 20,
            id: 'DBVonu',
          },
          {
            S: 234,
            L: 18,
            y: 16,
            id: 'iiS4in',
          },
          {
            S: 252,
            L: 18,
            y: 18,
            id: 'gyIEIk',
          },
          {
            S: 270,
            L: 18,
            y: 20,
            id: 'iLaG3k',
          },
          {
            S: 288,
            L: 36,
            y: 15,
            id: 'W-EkK5',
          },
          {
            S: 342,
            L: 18,
            y: 15,
            id: 'qF4Wxn',
          },
          {
            S: 360,
            L: 18,
            y: 15,
            id: 'wRfXLi',
          },
          {
            S: 378,
            L: 18,
            y: 12,
            id: 'edqtUw',
          },
          {
            S: 396,
            L: 18,
            y: 13,
            id: 'RBJyLh',
          },
          {
            S: 414,
            L: 18,
            y: 15,
            id: 'T27F4L',
          },
          {
            S: 432,
            L: 72,
            y: 8,
            id: '85TxMC',
          },
          {
            S: 504,
            L: 36,
            y: 20,
            id: 'gJwU3-',
          },
        ],
        barsNumber: 2,
        gridNumber: 4,
        barIndex: 0,
        trackName: 'melody',
      },
    ],
    chord: [],
    bass: [],
  },
  volume: {
    drums: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    melody: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    chord: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
    bass: {
      value: -15,
      muted: false,
      pan: 0,
      echo_loss: 0,
      echo_interval: 0,
      reverb: 0.001,
    },
  },
  resources: {
    drums: 'AcousticDrum',
    melody: 'ViolinPlaying',
    chord: 'Piano',
    bass: 'ElectricBass',
  },
};
