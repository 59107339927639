import React from "react"
import './index.css';

export default function Sounds() {
    const icon808 = require('../../assets/instruments/icon_808.png')
    const iconBass = require('../../assets/instruments/icon_bass.png')
    const iconBirdie = require('../../assets/instruments/icon_birdie.png')
    const iconClarinet = require('../../assets/instruments/icon_clarinet.png')
    const iconDrumSet = require('../../assets/instruments/icon_drum_set.png')
    // const iconGuitar = require('../../assets/instruments/icon_guitar.png')
    const iconGuzheng = require('../../assets/instruments/icon_guzheng.png')
    const iconKinderDrums = require('../../assets/instruments/icon_kinder_drums.png')
    const iconMarimba = require('../../assets/instruments/icon_marimba.png')
    const iconMelodica = require('../../assets/instruments/icon_melodica.png')
    const iconPiano = require('../../assets/instruments/icon_piano.png')
    const iconRecorder = require('../../assets/instruments/icon_recorder.png')
    const iconSaw = require('../../assets/instruments/icon_saw.png')
    const iconSawBass = require('../../assets/instruments/icon_saw_bass.png')
    const iconSquare = require('../../assets/instruments/icon_square.png')
    // const iconSquareBass = require('../../assets/instruments/icon_square_bass.png')
    const iconSummer = require('../../assets/instruments/icon_summer.png')
    const iconViolin = require('../../assets/instruments/icon_violin.png')
    return(
      <div className="div-center">
      <div className="sounds-container">
        <div className="items-title">
          玩转各式音色
        </div>
        <div>
        <img alt="808" src={icon808} />
        <img alt="bass" src={iconBass} />
        <img alt="birdie" src={iconBirdie} />
        <img alt="clarinet" src={iconClarinet} />
        <img alt="drum_set" src={iconDrumSet} />
        <img alt="violin" src={iconViolin} />
        <img alt="guzheng" src={iconGuzheng} />
        <img alt="kinder_drums" src={iconKinderDrums} />
        <img alt="marimba" src={iconMarimba} />
        <img alt="melodica" src={iconMelodica} />
        <img alt="piano" src={iconPiano} />
        <img alt="recorder" src={iconRecorder} />
        <img alt="saw" src={iconSaw} />
        <img alt="saw_bass" src={iconSawBass} />
        <img alt="square" src={iconSquare} />
        <img alt="summer" src={iconSummer} />
        </div>
        <div className="items-title">
          还有更多等待你发现……
        </div>
      </div>
    </div>
    )
  }